(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    var class2add = ['full', 'half', 'third', 'fourth'],
      cl = $('.banner-boxes > ul > li').length - 1,
      $bannerBoxes = $('.component-content > .banner-spot-content .banner-boxes'),
      $ctaBoxes = $('.banner-spot-elements'),
      $ctaContainer = $('.banner-spot-content'),
      bh = $ctaContainer.height() - $ctaBoxes.height();
    $bannerBoxes.parent().parent().after($bannerBoxes);
    $('.banner-boxes > ul > li').addClass(class2add[cl] + ' left').wrapInner('<div class="usp-content"></div>');
    $('.banner-boxes > ul > li > .usp-content').each(function () {
      if ($(this).find('a').attr('href') !== undefined) {
        $(this).wrap("<a href='" + $(this).find('a').attr('href') + "'></a>");
      }
    });
    window.DGS.EqualHeight.getInstance().register($('.usp-content .image-spot-element-text'), true);
    $('.banner-spot .component-content').append($('.banner-boxes'));
    $('.banner-spot').each(function () {
      var $this = $(this),
        $bText = $('.banner-text', $this),
        $ctas = $('.banner-boxes', $this);
      window.DGS.Overflow.getInstance().register($this, $('.banner-text', $this), function () {
        var rect1 = $bText[0].getBoundingClientRect(),
          rect2 = $ctas[0].getBoundingClientRect();
        if (!(rect1.right < rect2.left || rect1.left > rect2.right || rect1.bottom < rect2.top || rect1.top > rect2.bottom)) {
          return true;
        }
        return false;
      });
    });
    window.DGS.Event.getInstance().on("banner-spot.height.changed", function () {
      bh = $ctaContainer.height() - $ctaBoxes.height();
      $bannerBoxes.css('top', bh);
    });
  });
})(jQuery);