var moveHeadlineToTextContainer = function moveHeadlineToTextContainer() {
  var newTextImageSpot = $('.text-image-spot.headline-overlap');
  if (newTextImageSpot.length) {
    newTextImageSpot.each(function (index, item) {
      var headline = $(item).find($('.text-image-spot-image-description'));
      var textContainer = $(item).find($('.text-image-spot-text-container'));
      if ($(item).hasClass('headline-2')) {
        var headlineText = headline[0].innerText;
        var headlineH2 = $("<h2 class=\"text-image-spot-image-description\">".concat(headlineText, "</h2>"));
        textContainer.prepend(headlineH2);
        headline.remove();
      } else {
        textContainer.prepend(headline);
      }
    });
  }
};
DGS.OnLoad.getInstance().register(function () {
  moveHeadlineToTextContainer();
});