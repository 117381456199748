(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.option-spot').each(function () {
      window.DGS.EqualHeight.getInstance().register($('.option-spot-element-header', $(this)), true);
      window.DGS.EqualHeight.getInstance().register($('.option-spot-element-text', $(this)), true);
    });
    // INJECT span to links of disc type
    $('.option-spot-element-icon a').each(function () {
      $(this).html('<span>&#xe601;</span>');
    });
  });
})(jQuery);