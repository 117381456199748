import { addTrackingToProductClick } from './gtm-tracking';
import { trackHearingTest } from './gtm-tracking';
import { trackLoginClick } from './gtm-tracking';
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    if ($("body.new-navigation").length) {
      var correctScrollForAnchor = function correctScrollForAnchor() {
        $('[href^="#"]:not(".cd-top")').on("click", function (e) {
          e.preventDefault();
          var target = $(this).attr("href");
          var correction = $("#header").outerHeight(true);
          $("body,html").animate({
            scrollTop: $(target).offset().top - correction
          }, 500);
        });
      };
      var makePhoneNumberClickableForMobile = function makePhoneNumberClickableForMobile() {
        $(".clickable-phone-number-mobile-only").each(function () {
          var self = $(this);
          var phoneNumber = self[0].innerText;
          var MOBILE_BP = window.matchMedia("(max-width: 767px)");
          if (MOBILE_BP.matches) {
            self.wrap("<a href='tel:".concat(phoneNumber, "'></a>"));
          } else {
            self.closest("[href=\"tel:".concat(phoneNumber, "\"]")).replaceWith(self);
          }
        });
      };
      correctScrollForAnchor();

      // ANCHOR LINKING LOGIC PART - overwriting legacy code
      DGS.OnContentLoaded.getInstance().register(function () {
        var hash = window.location.hash;
        if (hash) {
          if ($(hash).length > 0) {
            setTimeout(function () {
              if ($(hash).offset().top > 0) {
                var $header = $('.header');
                var headerHeight = $header.outerHeight();
                $('html, body').animate({
                  scrollTop: $(hash).offset().top - headerHeight
                }, 200);
              }
            }, 200); // This is the time we wait for the components to load
          }
        }
      });
      window.DGS.Resizer.getInstance().register(function () {
        makePhoneNumberClickableForMobile();
      });
    }

    //Focusing elements only when keybord is in use (accessibility feature)

    function manageFocusOnTabKeyUse(e) {
      if (e.keyCode === 9) {
        $("body").addClass("user-is-tabbing");
        $(window).off("keydown", manageFocusOnTabKeyUse);
        $(window).on("mousedown", manageFocusOnMouseUse);
      }
    }
    function manageFocusOnMouseUse() {
      $("body").removeClass("user-is-tabbing");
      $(window).off("mousedown", manageFocusOnMouseUse);
      $(window).on("keydown", manageFocusOnTabKeyUse);
    }
    function manageFocus() {
      $(window).on("keydown", manageFocusOnTabKeyUse);
    }
    manageFocus();

    //Tracking GA4
    var handleTrackingOnLinkClickToProductPage = function handleTrackingOnLinkClickToProductPage() {
      var linksToProduckPages = document.querySelectorAll("a[href*='/hearing-aid-users/hearing-aids/']");
      linksToProduckPages.forEach(function (link) {
        var target = link.target.length > 0 ? link.target : "_self";
        link.addEventListener("click", function (e) {
          if (!link.parentElement.classList.contains("level3")) {
            addTrackingToProductClick(e, link.href, target, "button_click");
          }
        });
      });
    };
    handleTrackingOnLinkClickToProductPage();
    var buttons = document.querySelectorAll("#hearing-test-new a span.button.white");
    buttons.forEach(function (button) {
      button.addEventListener("click", function (e) {
        trackHearingTest(0, "info_page", 0);
      });
    });
    var loginButton = document.querySelectorAll("body.new-navigation .extranet-login .forgot-login input[type=submit]");
    var loginInput = document.querySelector("body.new-navigation .extranet-login div.password > label");
    var passwordInput = document.querySelector("body.new-navigation .extranet-login div.username > label");
    loginButton.forEach(function (button) {
      button.addEventListener("click", function (e) {
        if (loginInput.classList.contains("completed") && passwordInput.classList.contains("completed")) {
          trackLoginClick("login");
        }
      });
    });
    var requestButton = document.querySelectorAll("body.new-navigation .extranet-login .request-account a");
    requestButton.forEach(function (button) {
      button.addEventListener("click", function (e) {
        trackLoginClick("request_login");
      });
    });
  });
})(jQuery);