/* eslint-disable func-names */

import WDHFeatureB2BCartSpot from '../../../../wdh-feature/wdh-feature-b2b/src/js/cart-spot';
import { addTrackingToProductClick } from '../../js/gtm-tracking';
import { trackProductClick } from '../../js/gtm-tracking';
if ($('body.new-navigation').length) {
  var language = $('html').attr('lang');
  var dictionary = {
    Navigation: {
      SearchPlaceholder: 'Input',
      BackButton: 'Back',
      ProfessionalsLoggedIn: 'Logged in default'
    }
  };
  var is404page = $('#page-404').length;
  var page404Path = $('.breadcrumb.component ol li:last-of-type a').attr('href');
  var path = is404page ? page404Path : window.location.pathname;
  var $level2 = $('<ul class="menu-lv2"></ul>');
  var $searchfield = $('#header .search-field-spot');
  var $imageLogo = $('#header .container .component-content .image');
  var $extraNet = $('#header .extranet-menu-anonymous');
  var $loginSpot = $('#header .login-spot');
  var $profileUser = $('#header .profile-user');
  var $langSwitch = $('#header .language-selector-spot');
  var $mobileMenuTrigger = $('<button class="mobile-menu-trigger"><span></span><span></span><span></span></button>');
  var $mobileCloseSearch = $('<button class="close-search"><span></span><span></span></button>');
  var $backContainer = $('<div class="back-container"><div class="container-wrapper"><button class="back-nav" /></div></div>');
  var $shoppingCart = $('<div class="component wdh-feature-b2b-cartspot"><div class="component-content"></div></div>');
  var setActiveNavLinks = function setActiveNavLinks(level) {
    var navLevelClass;
    var condition;
    switch (level) {
      case 1:
        navLevelClass = '.menu-lv1 a';
        condition = function condition(href) {
          return path.indexOf(href) !== -1;
        };
        break;
      case 2:
        navLevelClass = '.level2 > a';
        condition = function condition(href) {
          var level2HrefParts = href.split('/');
          var locationParts = path.split('/');
          var level1IsEqual = level2HrefParts[level2HrefParts.length - 2] === locationParts[level2HrefParts.length - 2];
          var level2IsEqual = level2HrefParts[level2HrefParts.length - 1] === locationParts[level2HrefParts.length - 1];
          return level1IsEqual && level2IsEqual;
        };
        break;
      case 3:
        navLevelClass = '.level3 > a';
        condition = function condition(href) {
          return path === href || path === "".concat(href, "/");
        };
        break;
      default:
        break;
    }
    $(navLevelClass).removeClass('current').each(function () {
      var href = $(this).attr('href');
      if (condition(href)) {
        $(this).addClass('current');
      }
    });
  };
  var isDesktop = window.innerWidth >= 1024;
  $('.level1').each(function () {
    var $ul = $(this).find('>ul');
    var forLabel = $(this).find('>a').attr('href');
    var $li = $('<li></li>');
    $li.attr('data-for', forLabel);
    if (path.indexOf("".concat($li.attr('data-for'))) === -1) {
      $li.addClass('hidden');
    }
    $li.append($ul);
    $level2.append($li);
  });
  $('.navigation .component-content > ul').addClass('menu-lv1').after($level2);
  $('.level2 ul').hide();
  $searchfield.appendTo('.menu-lv1');
  $langSwitch.appendTo('.menu-lv2');
  $extraNet.appendTo('.menu-lv2');
  $profileUser.appendTo('.menu-lv2');
  if ($('#header .component .shopping-cart').length > 0) {
    $shoppingCart.appendTo('.menu-lv2');
  }
  $loginSpot.appendTo('.menu-lv2');
  $imageLogo.appendTo('.menu-lv2');
  setActiveNavLinks(1);
  setActiveNavLinks(2);
  setActiveNavLinks(3);
  $('#header').addClass('loaded');
  var setLogoLinkToCurrentHomePage = function setLogoLinkToCurrentHomePage() {
    var link = $imageLogo.find('a')[0];
    var currentHomePage = $('#header .level1 > a.current');
    if (currentHomePage.length) {
      link.href = currentHomePage[0].href;
    }
  };
  setLogoLinkToCurrentHomePage();
  $('.level2').each(function () {
    var subnavigation = $(this).find('>ul');
    if (subnavigation.length === 0) {
      $(this).addClass('no-subnav');
    } else {
      $(this).find('> a').wrapInner("<span></span>");
    }
  });
  var navigationUp = function navigationUp() {
    $('.menu-lv1').slideUp();
    $('.menu-lv1').removeClass('navIsOpen');
    $('.level2 .open').slideUp();
    $('.level2 > ul').removeClass('open');
    $('.level2 > a').removeClass('subnav-open');
  };
  var navigationDown = function navigationDown() {
    $('.level2 > ul.open:not(.current)').slideUp().removeClass('open');
    $('.level2 > a:not(.current)').removeClass('subnav-open');
    $('.menu-lv1').slideDown();
    $('.menu-lv1').addClass('navIsOpen');
    $('.level2 > a.current').addClass('subnav-open');
    $('.level2 > ul.current').addClass('open');
    $('.level2 .open').slideDown();
  };
  var manageHeaderOnScroll = function manageHeaderOnScroll() {
    var scrollPos = 0;
    var HOLD_UP = 150;
    $(window).on('scroll', function () {
      if (window.innerWidth > 1023) {
        var currentScrollPos = $(window).scrollTop();
        if (Math.abs(scrollPos - currentScrollPos) <= HOLD_UP) {
          return;
        }
        if (currentScrollPos > scrollPos) {
          navigationUp();
        } else {
          navigationDown();
        }
        scrollPos = currentScrollPos;
      }
    });
  };
  manageHeaderOnScroll();
  var manageNavigationOnHover = function manageNavigationOnHover() {
    var navigationIsUp = !$('.subnav-open').length;
    if (navigationIsUp && isDesktop) {
      navigationDown();
    }
  };
  $('.navigation').mouseenter(manageNavigationOnHover);
  var cleanMobileSubNav = function cleanMobileSubNav() {
    $('.navigation').removeClass('mobile-subnav-open');
    $('.level1').show();
    $('.level2 ul').hide();
    $('.level2').show();
    $('.level2 > a').removeClass('active');
  };
  var toggleMobileSubnavOpen = function toggleMobileSubnavOpen(el) {
    if (!isDesktop) {
      var hasSubnavigation = $(el).parent().find('>ul').length;
      if (hasSubnavigation) {
        $('level2 > a').removeClass('active');
        $('level2 > .open').removeClass('open');
        $(el).addClass('active');
        $(el).parent().find('ul').slideDown();
        $('.navigation').toggleClass('mobile-subnav-open');
        if (!$('.navigation.mobile-open').length) {
          $('.navigation').removeClass('mobile-subnav-open');
        }
        var subNavOpen = $('.navigation').hasClass('mobile-subnav-open');
        if (subNavOpen) {
          $('.level1 > a').each(function () {
            if (!$(this).hasClass('current')) {
              $(this).parent().hide();
            }
          });
          $('.level2 > a').each(function () {
            if (!$(this).hasClass('active')) {
              $(this).parent().hide();
            }
          });
        } else {
          cleanMobileSubNav();
        }
      } else {
        var href = $(el).attr('href');
        window.location = "".concat(href);
      }
    }
  };
  $mobileMenuTrigger.appendTo('.mobile-nav-inner');
  $imageLogo.clone(true).off().appendTo('.mobile-nav-inner');
  $extraNet.clone(true).off().appendTo('.mobile-nav-inner');
  $profileUser.clone(true).off().appendTo('.mobile-nav-inner');
  if ($('#header .component .shopping-cart').length > 0) {
    $shoppingCart.clone(true).off().appendTo('.mobile-nav-inner');
  }
  $loginSpot.clone(true).off().appendTo('.mobile-nav-inner');
  $searchfield.clone(true).off().appendTo('.mobile-nav-inner');
  $mobileCloseSearch.appendTo('.mobile-nav-inner .search-field');
  $backContainer.insertAfter('.menu-lv1');
  $('.menu-lv1, .menu-lv2, .level2 ul, .mobile-nav-inner').wrapInner('<div class="container-wrapper"></div>');
  $('#header .component .shopping-cart').each(function () {
    $(this).remove();
    new WDHFeatureB2BCartSpot().run();
  });
  $('.back-nav').on('click', function (e) {
    e.preventDefault();
    cleanMobileSubNav();
  });
  $(window).on('resize', function () {
    isDesktop = window.innerWidth >= 1024;
    if (isDesktop) {
      cleanMobileSubNav();
      $('.navigation').removeClass('mobile-open');
      $('.mobile-menu-trigger').removeClass('open');
      $('body').removeClass('no-scroll');
    } else {
      $('.level2 .open').removeClass('open').hide();
    }
  });
  $('body').on('click', '.navigation .menu-lv1 a', function (e) {
    e.preventDefault();
    var href = $(this).attr('href');
    var $li = $('.menu-lv2 > .container-wrapper > li:not(".hidden")');
    $li.addClass('hidden');
    $('.navigation .menu-lv1 a').removeClass('current');
    $(this).addClass('current');
    window.location = "".concat(href);
  });
  $('body').on('click', '.level3 > a', function (e) {
    e.preventDefault();
    var href = $(this).attr('href');
    window.location = "".concat(href);
    var productName = href.split("/").at(-1);
    var product = ['alpha', 'viron', 'zerena', 'leox'];
    product.forEach(function (p) {
      if (p === productName) {
        trackProductClick(p, 'navigation_menu');
      }
    });
    setActiveNavLinks(3);
  });
  $('body').on('click', '.level2:not(".nav-btn"):not(".no-subnav") > a', function (e) {
    e.preventDefault();
    if (isDesktop) {
      var $target = $(this).closest('.level2');
      var $targetUL = $target.find('ul');
      var $allULs = $('.level2 ul');
      if ($targetUL.hasClass('open')) {
        $targetUL.removeClass('open');
        $(this).removeClass('subnav-open');
        $targetUL.slideUp();
      } else {
        $allULs.removeClass('open');
        $allULs.slideUp();
        $targetUL.addClass('open').slideToggle();
        $('.level2 > a').removeClass('subnav-open');
        $(this).addClass('subnav-open');
      }
    } else {
      toggleMobileSubnavOpen(this);
    }
    setActiveNavLinks(2);
  });
  $('body').on('click', '.navigation .search-field-spot', function () {
    return $('.navigation .search-field-spot').find('input').focus();
  });
  $('body').on('click', function (e) {
    var $target = $(e.target);
    if ($target.find('*').hasClass('search-open')) {
      $('.search-open').removeClass('search-open');
    }
  });
  $mobileMenuTrigger.on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('open');
    $('.navigation').toggleClass('mobile-open');
    $('body').toggleClass('no-scroll');
    if ($('.level3 > a.current').length) {
      toggleMobileSubnavOpen($('.level2 > a.current'));
    }
  });
  var showThirdLevelIfCurrent = function showThirdLevelIfCurrent() {
    var currentThirdLevel = $('.level3 > a.current');
    if (currentThirdLevel.length && isDesktop) {
      var currentThirdLevelContainer = currentThirdLevel.closest('.level2 > ul');
      $('.level2 > a.current').addClass('subnav-open');
      currentThirdLevelContainer.addClass('open current').show();
    }
  };
  var addPlaceholderToSearchField = function addPlaceholderToSearchField(text) {
    var input = $('#header .search-field-spot input');
    input.each(function (index, item) {
      $(item)[0].placeholder = text;
    });
  };
  var translateBackButton = function translateBackButton(text) {
    var backButton = $('.back-nav');
    backButton[0].innerText = text;
  };
  var translateProfessionals = function translateProfessionals(text) {
    var professionalsLink = $('.level1.change-professionals-link-on-logged-in > a');
    var userIsLoggedIn = $('body').hasClass('user-logged-in');
    var isGermany = language === 'de-de';
    if (userIsLoggedIn && isGermany && professionalsLink.length > 0) {
      professionalsLink[0].innerText = text;
    }
  };
  (function ($, DGS) {
    window.DGS.OnLoad.getInstance().register(function () {
      showThirdLevelIfCurrent();
    });
    DGS.Dictionary.getInstance().getTexts(dictionary, function () {
      addPlaceholderToSearchField(dictionary.Navigation.SearchPlaceholder);
      translateBackButton(dictionary.Navigation.BackButton);
      translateProfessionals(dictionary.Navigation.ProfessionalsLoggedIn);
    }, language);
  })(jQuery, window.DGS);
} else {
  var _$imageLogo = document.querySelectorAll('#header .container .component-content .image');
  var logos = Array.from(_$imageLogo);
  var addClassToSecondaryLogo = function addClassToSecondaryLogo() {
    if (logos.length === 2) {
      logos[0].classList.add('secondary-logo');
    }
  };
  addClassToSecondaryLogo();
}