import { trackWebsiteInternalSearch } from "../gtm-tracking";
(function ($) {
  window.DGS.SeachSpotConfig = {
    initiateAfterBuilder: false
  };
  window.DGS.OnLoad.getInstance().register(function () {
    var mainSearch = $(".search-field-spot .component-content .search-field"),
      assetPath = "/webservices/search.svc/",
      searchResult = "search/",
      searchString = "",
      lazyLoad = true,
      loadPage = 1,
      currentSearch = window.location.href,
      searchAktiv = false,
      isLocal = false,
      language = $("html").attr("lang");
    $(".new-navigation .search-field-spot .search-icon").prepend('<button class="search-icon-button" aria-label="search"></button>');
    if ($(".search-result-spot").length) {
      if (!$(".search-result-spot").data("no-results")) {
        $(".no-search-result").remove();
        window.DGS.Event.getInstance().trigger("trackSearchResults");
        trackWebsiteInternalSearch(true);
      } else {
        //$('.no-search-result').css('display','block'); // NEEDS TO BE STYLED AND RE-ADDED
        $(".search-result").css("margin-top", "4em");
        window.DGS.Event.getInstance().trigger("trackSearchNoResults");
        trackWebsiteInternalSearch(false);
      }
    }
    if ($("#wrapper").has(".search-result-spot").length > 0) {
      // do nothing
      $(".search-field-spot").hide();
    } else {
      searchResult = $(".search-field-spot").attr("data-result-page");
      $(".search-field-spot").on("click", function (e) {
        var $this = $(this);
        setTimeout(function () {
          $("input", $this).focus();
        }, 310);
        if ($this.hasClass("unbinded")) {
          return false;
        }
        e.preventDefault();
        var target = $(e.target),
          searchValue = $(".search-field-spot:visible input").val();
        if (!target.is("input") && !target.is(".search-icon")) {
          $this.toggleClass("search-open");
          if ($this.siblings(".navigation").length > 0) {
            $this.siblings(".navigation").toggleClass("no-dropdown");
          }
          //$('#wrapper > #content').toggleClass('full-blur');
          if (searchAktiv) {
            searchAktiv = false;
            $(".blur-overlay").remove();
          } else {
            searchAktiv = true;
            $("#wrapper > #content").prepend('<div class="blur-overlay"></div>');
            $(".blur-overlay").on("click", function (e) {
              e.preventDefault();
              $this.toggleClass("search-open");

              //$('#wrapper > #content').toggleClass('full-blur');
              searchAktiv = false;
              $(".blur-overlay").remove();
            });
          }
          $("input", $this).focus();
        } else if (target.is(".search-icon") && searchValue != "") {
          searchString = searchValue;
          currentSearch = searchResult + "?q=" + removeSpecialChars(searchString);
          window.location = currentSearch;
        }
      });
    }
    var initSearchFieldSpot = function initSearchFieldSpot() {
      $(".search-field-spot").each(function () {
        var $this = $(this),
          liWidth = $("input", $this).width(),
          searchResult = $this.attr("data-result-page");
        $("input", $this).autocomplete({
          source: function source(request, response) {
            $.ajax({
              url: assetPath + "suggest/" + $("input", $this).val() + "/" + language,
              type: "GET",
              dataType: "json",
              success: function success(data) {
                response(data.Suggests);
              },
              error: function error(xhr, textStatus, errorThrown) {
                console.log("error search-field-spot ", url);
              }
            });
          },
          select: function select(a, b) {
            searchString = "?q=" + removeSpecialChars(b.item.value);
            window.location = searchResult + searchString;
          },
          open: function open() {
            $("ul.ui-menu").width($(this).innerWidth());
          }
        });
        $this.keypress(function (e) {
          var searchText = $("input", $this).val().trim();
          if (e.keyCode == 13 && searchText != "") {
            e.preventDefault();
            searchString = "?q=" + removeSpecialChars(searchText);
            window.location = searchResult + searchString;
          }
        });
        $("#content .search-icon").click(function (e) {
          var searchText = $("input", $this).val().trim();
          if (searchText != "") {
            searchString = "?q=" + removeSpecialChars(searchText);
            window.location = searchResult + searchString;
          }
        });
      });
    };
    if (window.DGS.SeachSpotConfig.initiateAfterBuilder) {
      window.DGS.Event.getInstance().on(window.DGS.Events.BUILDER_DONE, function () {
        initSearchFieldSpot();
      });
    } else {
      initSearchFieldSpot();
    }
    $(".search-result-spot").each(function () {
      var $this = $(this),
        liWidth = $("input", $this).width(),
        searchResult = $this.attr("data-result-page"),
        $span = $(".search-result-count span");

      //$('.search-result-count').wrapInner('<span></span>');
      searchString = $("input", $this).val();
      $("input", $this).autocomplete({
        source: function source(request, response) {
          $.ajax({
            url: assetPath + "suggest/" + $("input", $this).val() + "/" + language,
            type: "GET",
            dataType: "json",
            success: function success(data) {
              response(data.Suggests);
            },
            error: function error(xhr, textStatus, errorThrown) {
              console.log("error search-result-spot ", url);
            }
          });
        },
        select: function select(a, b) {
          searchString = "?q=" + removeSpecialChars(b.item.value);
          currentSearch = searchString;
          window.location = currentSearch;
        },
        open: function open() {
          $("ul.ui-menu").width($(this).innerWidth());
        }
      });
      $this.keypress(function (e) {
        var searchText = $("input", $this).val().trim();
        if (e.keyCode == 13 && searchText != "") {
          e.preventDefault();
          searchString = "?q=" + removeSpecialChars(searchText);
          window.location = searchString;
        }
      });
      $this.on("click", function (e) {
        e.stopPropagation();
        var offset = $span.offset(),
          $target = $(e.target);
        if (e.offsetX > $span.width() && e.offsetX < $span.width() + 40 && $target.is("span")) {
          searchString = "?q=" + removeSpecialChars($("input", $this).val());
          window.location = searchString;
        } else {
          $("input", $this).focus();
        }
      });
      $(".search-result-count, .search-icon").click(function (e) {
        searchString = "?q=" + removeSpecialChars($(this).parent().children("input").val());
        window.location = searchString;
      });
    });
    function searchScroll(windowScroll) {
      var $bc = $("#wrapper .component.breadcrumb"),
        $footer = $("#wrapper #footer"),
        offset = $bc.length ? $bc.offset() : $footer.length ? $footer.offset() : null,
        wh = $(window).height(),
        liCount = $(".search-result ul li").length;
      if (offset != null && offset.top - wh < windowScroll && lazyLoad && liCount >= 10) {
        lazyLoad = false;
        loadPage++;
        currentSearch = assetPath + searchResult + searchString + "/" + loadPage;
        nextPage();
      }
    }
    function nextPage() {
      var loadMore = true,
        $searchResult = $(".search-result-spot .search-result"),
        liTemplate = $("#search-element-template").html(),
        ulHeight = $(".search-result ul")[0].scrollHeight,
        loadMoreTemplate = $("#loading-more-template").html();
      $searchResult.append(loadMoreTemplate);
      $.ajax({
        url: currentSearch + "/" + language,
        type: "GET",
        dataType: "json",
        success: function success(data, textStatus, xhr) {
          var newHtml = "";
          $.each(data.Elements, function (index, liData) {
            var theData = liTemplate.format(liData.Url, liData.Title, liData.NiceUrl, liData.Summary, liData.Number);
            newHtml += theData;
            if (liData.Number >= data.Count) loadMore = false;
          });
          $(".loading-more").remove();
          $("ul", $searchResult).append(newHtml);
          if (loadMore) lazyLoad = true;
        },
        error: function error(xhr, textStatus, errorThrown) {
          console.log("error nextPage ", url);
        }
      });
    }
    window.DGS.Scroller.getInstance().register(searchScroll);
    function removeSpecialChars(query) {
      if (query != "") {
        var restrictedChars = $(".search-field-spot").attr("data-restricted-chars");
        if (restrictedChars.length > 0) {
          var restrictedCharsList = restrictedChars.split("");
          for (var i = 0; i < restrictedCharsList.length; i++) {
            query = query.split(restrictedCharsList[i]).join(" ");
          }
        }
      }
      return query;
    }
  });
})(jQuery);