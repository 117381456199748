(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    var toogleMode = false,
      $mainDiv = $('.component.rich-text-list.footer-fix .component-content ul li');
    $mainDiv.each(function () {
      $(this).on('click', function () {
        if (toogleMode) {
          var me = $(this).find('ul');
          me.toggle().parent('li').css('height', 'auto');
        }
      });
    });
    function setToggle() {
      if (window.matchMedia('(min-width: 600px)').matches) {
        toogleMode = false;
        $('ul', $mainDiv).each(function () {
          $(this).css('display', 'block');
        });
      } else {
        toogleMode = true;
        $('ul', $mainDiv).each(function () {
          $(this).css('display', 'none');
        });
      }
    }
    window.DGS.Resizer.getInstance().register(setToggle);
    window.DGS.Justify.getInstance().apply($('body:not(".new-navigation") #wrapper #footer .section-one .component.rich-text-list > .component-content > ul'), 'li');
    window.DGS.EqualHeight.getInstance().register($('.footer-fix .component-content > ul > li > h3'), true);
    window.DGS.EqualHeight.getInstance().register($('.footer-fix .component-content > ul > li:not(.stretch)'), true, function (height) {
      $('.footer-fix .component-content > ul').height(height);
    });
  });
})(jQuery);