import { gsap } from "gsap";
import ScrollToPlugin from 'gsap/ScrollToPlugin';
(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    if (!$("#wrapper .cookie-warning").length) {
      // There are no sitecore cookie components on page. Break out
      return;
    }
    $("#wrapper .cookie-warning").insertBefore('#header');
    var cookieWarning = $("#wrapper .cookie-warning:not(.initialized)"),
      isEmpty = cookieWarning.find(".component-content").children().length;
    var tween = null;
    if (isEmpty) {
      cookieWarning.addClass("initialized component-ready");
    } else {
      cookieWarning.remove();
    }
    $('#wrapper .cookie-warning .submit').click(function (e) {
      e.preventDefault();
      window.DGS.Cookies.getInstance().createCookie("cookie-warning", 1, 365);
      tween.kill();
      tween = gsap.to($('#wrapper'), {
        duration: .3,
        css: {
          marginTop: 0,
          marginBottom: 0
        },
        delay: 0
      });
      cookieWarning.remove();
      window.DGS.Event.getInstance().trigger('cookie-warning-submit');
    });
    window.DGS.Resizer.getInstance().register(function () {
      var isEmpty = cookieWarning.find(".component-content").children().length,
        newMargin = cookieWarning.outerHeight();
      if (window.matchMedia("(max-width: 1024px)").matches) {
        if (isEmpty) {
          tween = gsap.to($('#wrapper'), {
            duration: .3,
            css: {
              marginBottom: newMargin,
              marginTop: 0
            },
            delay: 1.5
          });
        } else {
          tween = gsap.to($('#wrapper'), {
            duration: .3,
            css: {
              marginTop: 0,
              marginBottom: 0
            },
            delay: 0
          });
        }
      } else if (isEmpty) {
        tween = gsap.to($('#wrapper'), {
          duration: .3,
          css: {
            marginTop: newMargin,
            marginBottom: 0
          },
          delay: 1.5
        });
      } else {
        tween = gsap.to($('#wrapper'), {
          duration: .3,
          css: {
            marginTop: 0,
            marginBottom: 0
          },
          delay: 0
        });
      }
    });
  });
})(jQuery);
(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    var $header = $('#wrapper > #header'),
      $submenuSpot = $('#wrapper > #content .submenu-spot'),
      hasSubmenuSpot = $submenuSpot.length,
      submenuSpotTop = 80,
      cookieWarning = $(".cookie-warning:not(.initialized)");
    var onUpdate = function onUpdate() {
        if (!$('#wrapper > #header').length) {
          $('#header').css('top', 0);
        }
      },
      tweenTo = function tweenTo(top, delay) {
        gsap.to($header, {
          duration: .3,
          css: {
            top: top
          },
          delay: delay,
          onUpdate: onUpdate,
          onComplete: onUpdate
        });
        if (hasSubmenuSpot) gsap.to($submenuSpot, {
          duration: .3,
          css: {
            top: 80 + top
          },
          delay: delay
        });
      };
    window.DGS.Event.getInstance().on('cookie-warning-submit', function () {
      tweenTo(0, 0);
    });
    window.DGS.Resizer.getInstance().register(function () {
      var isEmpty = cookieWarning.find(".component-content").children().length,
        newMargin = cookieWarning.outerHeight();
      if ($header.length) {
        if (window.matchMedia("(max-width: 1024px)").matches) {
          if (isEmpty) {
            tweenTo(0, 1.5);
          } else {
            tweenTo(0, 0);
          }
        } else if (isEmpty) {
          tweenTo(newMargin, 1.5);
        } else {
          tweenTo(0, 0);
        }
      } else {
        tweenTo(0, 0);
      }
    });
  });
})(jQuery);