require('core-js');require('regenerator-runtime/runtime');import $ from "jquery";
import { TweenMax } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import cssLoader from '../lazy-loading-css-config';
cssLoader();
window.jQuery = $;
window.$ = $;
require('./ie-polyfills');
require('@demant/legacy/custom-scroll');
require('../../../wdh-foundation/dateformat/dateformat.js');
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
require('@demant/megamenu');
require('../../../wdh-foundation/dgs-utils/dgs-utils.js');
require('../../../wdh-foundation/dgs-sharing/dgs-sharing.js');
require('@demant/legacy/googlemaps');
require('iframe-resizer');
require('jquery-nice-select/js/jquery.nice-select.min.js');
require('jquery-ui-dist/jquery-ui.min.js');
require('lightgallery/src/js/lightgallery.js');
require('@demant/legacy/lunametrics');
require('jquery-bridget');
require('masonry-layout');
require('moment');
require('slick-carousel/slick/slick.min.js');

// Feature imports
require('./feature/accordion-spot/index.js');
require('./feature/component-center-list-spot.js');
require('./feature/component-data-list-spot.js');
require('./feature/component-distributor-locator.js');
require('./feature/component-download-center.js');
require('./feature/data-hierarchy-spot/component-a-data-hierarchy.js');
require('./feature/data-hierarchy-spot/component-b-data-hierarchy-appframe-component.js');
require('./feature/data-hierarchy-spot/component-b-data-hierarchy-hubspot.js');
require('./feature/data-hierarchy-spot/component-b-data-hierarchy-multi-step.js');
// require('./feature/data-hierarchy-spot/component-b-data-hierarchy-resource-center-base.js');
require('./feature/data-hierarchy-spot/component-data-hierarchy-dataset.js');
require('./feature/component-data-list-search-spot.js');
require('./feature/jquery-cycle.js');
require('./feature/component-iframe.js');
require('./feature/component-job-list-spot.js');
require('./feature/component-lattice-spot.js');
require('./feature/component-language-selector-spot.js');
require('./feature/component-leaving-notification.js');
require('./feature/component-link-list.js');
require('./feature/online-booking/a-datepicker-regionals.js');
require('./feature/online-booking/component-online-booking.js');
require('./feature/component-richtext-submenu.js');
require('./feature/component-screening-test.js');
require('./feature/component-search.js');
require('./feature/component-spot-control-spot.js');
require('./feature/component-text-image-spot.js');
require('./feature/component-tile.js');
require('./feature/component-banner-spot.js');
require('./feature/component-blog.js');
require('./feature/component-blog-categories.js');
require('./feature/component-box-spot.js');
require('./feature/component-color-selector-spot.js');
require('./feature/component-content.js');
require('./feature/component-cookies.js');
require('./feature/component-extranet.js');
require('./feature/component-customscroll.js');
require('./feature/custom-component-image-spot.js');
require('./feature/component-news-list-spot.js');
require('./feature/component-option-spot.js');
require('./feature/component-social-media.js');
require('./feature/custom-component-submenu.js');
require('./feature/wdh-feature-b2b');
require('./feature/center-regions-spot.js');
require('./feature/component-geo-ip-prompt');
require('./feature/component-video-spot');
require('./feature/flex-spot-components/custom-pop-ups/index');
require('./feature/flex-spot-components/roadmap-lp/index.jsx');
require('./feature/locations-spot/utils/gtm-tracking');

// Project files
require('./base-main-custom.js');
require('./tracking-gtm.js');
require('./custom-footer.js');
require('./custom-header.js');
require('./custom-a-login-spot.js');
require('./custom-login.js');
require('./custom-placeholders.js');
require('./custom-sharing.js');
require('./feature/new-text-image-spot.js');
require('./feature/navigation-template');

// new bernie
require('./main-new.js');
require('@demant/wdh-gdpr/lib/default.implementation.js');
require('./gtm-tracking');
    if (document.querySelector('.boxes-spot.flex-spot')) {
      import(/* webpackChunkName: "chunk-num-1", webpackPrefetch: true */'./feature/flex-spot-components/boxes-spot/index.js')
        .then(function (foo) { console.log('.boxes-spot.flex-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .boxes-spot.flex-spot'); console.log(error); });
    }
    if (document.querySelector('.hearing-center-locator')) {
      import(/* webpackChunkName: "chunk-num-2", webpackPrefetch: true */'./feature/component-hearing-center-locator.js')
        .then(function (foo) { console.log('.hearing-center-locator LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-center-locator'); console.log(error); });
    }
    if (document.querySelector('.hearing-test-spot:not(.quiz)')) {
      import(/* webpackChunkName: "chunk-num-3", webpackPrefetch: true */'./feature/wdh-hearing-test.js')
        .then(function (foo) { console.log('.hearing-test-spot:not(.quiz) LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-test-spot:not(.quiz)'); console.log(error); });
    }
    if (document.querySelector('.hearing-test-spot.quiz')) {
      import(/* webpackChunkName: "chunk-num-4", webpackPrefetch: true */'./feature/wdh-hearing-quiz.js')
        .then(function (foo) { console.log('.hearing-test-spot.quiz LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-test-spot.quiz'); console.log(error); });
    }
    if (document.querySelector('.intro-banner')) {
      import(/* webpackChunkName: "chunk-num-5", webpackPrefetch: true */'./feature/component-intro-banner.js')
        .then(function (foo) { console.log('.intro-banner LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .intro-banner'); console.log(error); });
    }
    if (document.querySelector('.locations-spot.component')) {
      import(/* webpackChunkName: "chunk-num-6", webpackPrefetch: true */'./feature/locations-spot/location-spot')
        .then(function (foo) { console.log('.locations-spot.component LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .locations-spot.component'); console.log(error); });
    }
    if (document.querySelector('.resource-center:not(.grid-layout)')) {
      import(/* webpackChunkName: "chunk-num-7", webpackPrefetch: true */'./feature/wdh-resource-center.js')
        .then(function (foo) { console.log('.resource-center:not(.grid-layout) LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .resource-center:not(.grid-layout)'); console.log(error); });
    }
    if (document.querySelector('.cd-top')) {
      import(/* webpackChunkName: "chunk-num-8", webpackPrefetch: true */'./custom-to-top.js')
        .then(function (foo) { console.log('.cd-top LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .cd-top'); console.log(error); });
    }
    if (document.querySelector('.scfSectionContent')) {
      import(/* webpackChunkName: "chunk-num-9", webpackPrefetch: true */'./custom-form.js')
        .then(function (foo) { console.log('.scfSectionContent LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .scfSectionContent'); console.log(error); });
    }
    if (document.querySelector('.extranet-login')) {
      import(/* webpackChunkName: "chunk-num-10", webpackPrefetch: true */'./custom-form.js')
        .then(function (foo) { console.log('.extranet-login LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .extranet-login'); console.log(error); });
    }
    if (document.querySelector('.anonymous-comment-form')) {
      import(/* webpackChunkName: "chunk-num-11", webpackPrefetch: true */'./custom-form.js')
        .then(function (foo) { console.log('.anonymous-comment-form LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .anonymous-comment-form'); console.log(error); });
    }
    if (document.querySelector('.authenticated-comment-form')) {
      import(/* webpackChunkName: "chunk-num-12", webpackPrefetch: true */'./custom-form.js')
        .then(function (foo) { console.log('.authenticated-comment-form LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .authenticated-comment-form'); console.log(error); });
    }
    if (document.querySelector('.comments')) {
      import(/* webpackChunkName: "chunk-num-13", webpackPrefetch: true */'./feature/component-comments.js')
        .then(function (foo) { console.log('.comments LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .comments'); console.log(error); });
    }
    if (document.querySelector('.contact-information')) {
      import(/* webpackChunkName: "chunk-num-14", webpackPrefetch: true */'./feature/component-contact-information.js')
        .then(function (foo) { console.log('.contact-information LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .contact-information'); console.log(error); });
    }
    if (document.querySelector('.country-selector')) {
      import(/* webpackChunkName: "chunk-num-15", webpackPrefetch: true */'./feature/component-country-selector.js')
        .then(function (foo) { console.log('.country-selector LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .country-selector'); console.log(error); });
    }
    if (document.querySelector('.hearing-loss-simulator-spot.component')) {
      import(/* webpackChunkName: "chunk-num-16", webpackPrefetch: true */'../wdh-hearing-loss-simulator/index.js')
        .then(function (foo) { console.log('.hearing-loss-simulator-spot.component LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-loss-simulator-spot.component'); console.log(error); });
    }
    if (document.querySelector('.embed-form-container')) {
      import(/* webpackChunkName: "chunk-num-17", webpackPrefetch: true */'./feature/forms/embed-form.js')
        .then(function (foo) { console.log('.embed-form-container LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .embed-form-container'); console.log(error); });
    }
    if (document.querySelector('.slider')) {
      import(/* webpackChunkName: "chunk-num-18", webpackPrefetch: true */'./feature/flex-spot-components/slider/index.jsx')
        .then(function (foo) { console.log('.slider LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .slider'); console.log(error); });
    }
    if (document.querySelector('.video-banner')) {
      import(/* webpackChunkName: "chunk-num-19", webpackPrefetch: true */'./feature/flex-spot-components/video-banner/index.js')
        .then(function (foo) { console.log('.video-banner LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .video-banner'); console.log(error); });
    }
    if (document.querySelector('.product-order')) {
      import(/* webpackChunkName: "chunk-num-20", webpackPrefetch: true */'./feature/product-order.js')
        .then(function (foo) { console.log('.product-order LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .product-order'); console.log(error); });
    }
    if (document.querySelector('.performance-component.flex-spot')) {
      import(/* webpackChunkName: "chunk-num-21", webpackPrefetch: true */'./feature/flex-spot-components/performance-component/index.jsx')
        .then(function (foo) { console.log('.performance-component.flex-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .performance-component.flex-spot'); console.log(error); });
    }
    if (document.querySelector('.boxes-spot')) {
      var link = document.querySelector('link[data-component^="boxes-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.country-selector')) {
      var link = document.querySelector('link[data-component^="country-selector"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.download-center')) {
      var link = document.querySelector('link[data-component^="download-center"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.extranet')) {
      var link = document.querySelector('link[data-component^="extranet"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-center-locator')) {
      var link = document.querySelector('link[data-component^="hearing-center-locator"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-loss-simulator')) {
      var link = document.querySelector('link[data-component^="hearing-loss-simulator"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-test')) {
      var link = document.querySelector('link[data-component^="hearing-test"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.locations-spot')) {
      var link = document.querySelector('link[data-component^="locations-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.quiz')) {
      var link = document.querySelector('link[data-component^="quiz"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.resource-center')) {
      var link = document.querySelector('link[data-component^="resource-center"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.embed-form')) {
      var link = document.querySelector('link[data-component^="embed-form"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.product-order')) {
      var link = document.querySelector('link[data-component^="product-order"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.performance-component')) {
      var link = document.querySelector('link[data-component^="performance-component"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }