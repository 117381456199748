/**
 * Created by hjac on 26-04-2017.
 */
(function ($) {
  var socialSharingConfig = {
    layout: 'float',
    serviceElementsHeaderClass: 'service-elements-header',
    serviceElementClass: 'service-element',
    shareWindowDimensions: 'width=626,height=436',
    restrictBoundariesSelectors: {
      top: '.blog-categories-content',
      // so it does not go ABOVE the categories
      bottom: '#footer' // Or BELOW the footer.
    }
  };
  window.DGS.OnLoad.getInstance().register(function () {
    $('#social-sharing').sharingTools(socialSharingConfig);
    window.DGS.Resizer.getInstance().register(function () {
      var $shareComponent = $('#social-sharing');
      var isMobile = window.matchMedia('(max-width: 991px)').matches;
      if ($shareComponent.length) {
        // if sharecomponent is placed at bottom
        if ($shareComponent.offset().top + $shareComponent.height() >= $(window).height() && isMobile) {
          if ($shareComponent.hasClass('float')) {
            var socialSharingHeight = $shareComponent.outerHeight();
            $('#footer').css('margin-bottom', socialSharingHeight);
          }
        } else {
          $('#footer').css('margin-bottom', 0);
        }
      }
    });
  });
})(jQuery, window.DGS);