var setupCookiesErrorMsg = function setupCookiesErrorMsg(container) {
  var dictionary = {
    VideoSpot: {
      Header: "Back",
      Description: "Test",
      ButtonLabel: "Change cookie settings"
    }
  };
  var language = $("html").attr("lang");
  var buttonHandler = function buttonHandler(e) {
    e.preventDefault();
    window.CookieConsent.renew();
    TogglePage($(".coi-banner__nextpage"), "coiPage-2");
  };
  DGS.Dictionary.getInstance().getTexts(dictionary, function () {
    $(container).append([$("<div/>", {
      class: "video-spot-overlay-error-msg-content"
    }).append([$("<h4/>", {
      class: "video-spot-overlay-error-msg-header",
      text: dictionary.VideoSpot.Header
    }), $("<p/>", {
      class: "video-spot-overlay-error-msg-desc",
      text: dictionary.VideoSpot.Description
    }), $("<button/>", {
      id: "video-spot-overlay-error-msg-btn",
      text: dictionary.VideoSpot.ButtonLabel,
      click: function click(e) {
        return buttonHandler(e);
      }
    })])]);
  }, language);
};
export default setupCookiesErrorMsg;