(function () {
  var determineLayout = function determineLayout() {
    // run through styles and calculate which renders links in one line
    var $this = $('#wrapper .component.submenu-spot'),
      classes = ['four', 'three', 'two', 'one'],
      links = $('.component-content > ul > li > a', $this),
      linkHeight = 0,
      layoutAchieved = false;
    classes.forEach(function (object, number) {
      if (!layoutAchieved) {
        classes.forEach(function (obj, num) {
          $this.removeClass(obj);
        });
        $this.addClass(object);
        layoutAchieved = true;
        links.each(function () {
          if (layoutAchieved) {
            if (linkHeight == 0) {
              linkHeight = $(this).height();
            } else {
              if ($(this).height() != linkHeight) {
                layoutAchieved = false;
              }
            }
          }
        });
      }
    });
  };
  window.DGS.OnLoad.getInstance().register(function () {
    window.DGS.Resizer.getInstance().register(function () {
      determineLayout();
    });
    $('.submenu-expander').off('click');
    $('.submenu-expander').on('click', function (e) {
      e.preventDefault();
      $('.submenu-spot ul').slideToggle(200);
      $('.submenu-spot').toggleClass('expanded');
      window.DGS.EqualHeight.getInstance().trigger($('.submenu-spot .component-content > ul > li'));
      determineLayout();
    });
    $('.submenu-bar > .title-link').on('click', function (e) {
      $('html, body').animate({
        scrollTop: 0
      }, "1000");
    });
  });
})(jQuery);