(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.blog-categories').each(function () {
      // Refactor to add page locator on the corresponding blog-categories elements.
      $('.blog-category a', $(this)).each(function () {
        var href = $(this).attr('href');
        var page_url = location.pathname + location.search;
        //if query string
        if (this.href.indexOf('?') != -1) {
          if (page_url.indexOf(href) === 0) {
            $(this).addClass('active');
            $('.blog-category a').first().removeClass('active');
          }
        } else {
          $('.blog-category a').first().addClass('active');
        }
      });
    });
  });
  window.DGS.Resizer.getInstance().register(function () {
    $('.component.blog-categories').each(function () {
      if ($(this).length && window.matchMedia("(min-width: 992px)").matches) {
        // move the categories on the top of the image for desktop layout
        var catMargin = 0 - $(this).outerHeight();
        $(this).addClass("add-opacity");
        $(this).css('margin-top', catMargin);
      } else {
        $(this).css('margin-top', '0');
      }
    });
  });
})(jQuery);