var cssLoader = function cssLoader() {
  var cssChunks = {
    ".boxes-spot.flex-spot": "boxes-spot",
    ".country-selector": "country-selector",
    ".download-center, .download-center-item-thumbnail-preview, .lg-backdrop": "download-center",
    ".extranet-login, .extranet-forgot-password, .extranet-reset-password, .extranet-request-account, .extranet-complete-account, .extranet-update-account": "extranet",
    ".hearing-center-locator": "hearing-center-locator",
    ".hearing-loss-simulator-spot.component": "hearing-loss-simulator",
    ".hearing-test-spot:not(.quiz)": "hearing-test",
    ".hearing-test-spot.quiz": "quiz",
    ".locations-spot": "locations-spot",
    ".resource-center": "resource-center",
    ".embed-form-container": "embed-form",
    ".product-order": "product-order",
    ".performance-component.flex-spot": "performance-component"
  };
  Object.keys(cssChunks).map(function (key) {
    var element = document.querySelector(key);
    var link = document.querySelector("link[data-component^=\"".concat(cssChunks[key], "\"]"));
    if (element && link) {
      link.setAttribute("href", link.getAttribute("data-href"));
    }
  });
};
export default cssLoader;