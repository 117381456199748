(function ($) {
  window.DGS.Resizer.getInstance().register(function () {
    $('.component.social-media').each(function () {
      var $blog = $('.component.blog');

      //only for blog page
      if ($('.component-content', $blog).length) {
        var $wrapper = $('.social-media-item-wrapper', $(this)),
          vw = $(window).width(),
          bw = $('.component-content', $blog).width(),
          sideSpace = (vw - bw) / 2;

        //show the social icons only if they fit into the side panel
        if (sideSpace > $wrapper.width()) {
          $(this).css('top', $('#header').height());
          $('.component-content', $(this)).css('width', sideSpace);
          $wrapper.css('opacity', 1);
        } else {
          $wrapper.css('opacity', 0);
        }
      }
    });
  });
})(jQuery);