(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    var labelsAsPlaceholders = $('.labels-as-placeholders');
    if (labelsAsPlaceholders.length > 0) {
      var labels = labelsAsPlaceholders.find('.scfEmailLabel');
      labels.each(function () {
        var textBox = $(this).next('div').find('[type="text"]');
        textBox.attr('placeholder', $(this).text());
      });
      $('.labels-as-placeholders .component-content>.scfForm').addClass('thanks');
      if ($('.labels-as-placeholders fieldset').length > 0) {
        $('.labels-as-placeholders .component-content>.scfForm').removeClass('thanks');
      }
    }
  });
})(jQuery);