(function ($) {
  window.DGS.GlobalSliderConfig = {
    slideInterval: 10000
  };

  // ONLOAD
  window.DGS.OnLoad.getInstance().register(function () {
    // INJECT span to links of disc type
    $('a.disc-right').each(function () {
      $(this).html('<span>&#xe603;</span>');
    });
    $('a.disc-down').each(function () {
      $(this).html('<span>&#xe601;</span>');
    });
    $('.animate-in.on-intro').addClass('animated');

    // SETUP PARALLAX EFFECTS
    $('.is-parallax').each(function () {
      var thisElem = $(this),
        thisHeight = thisElem.height(),
        offset = thisElem.offset(),
        offsetTop = offset.top,
        parallaxStart = offsetTop - $(window).height(),
        parallaxEnd = offsetTop + thisHeight,
        paraSpeed;
      $(window).on('scroll', function () {
        var windowScroll = $(window).scrollTop();
        if (windowScroll > parallaxStart && windowScroll < parallaxEnd) {
          // console.log('parallax is ACTIVE');
          paraSpeed = (windowScroll * 0.2 - offsetTop * 0.2).toFixed(0);
          $(thisElem).css({
            'transform': 'translate3d(0, ' + paraSpeed + 'px, 0)'
          });
        } else if (windowScroll < parallaxStart) {
          // console.log('parallax has not begun');
        } else if (windowScroll > parallaxEnd) {
          // console.log('parallax is over');
        }
        ;
      });
      var windowScroll = $(window).scrollTop();
      if (windowScroll > parallaxStart && windowScroll < parallaxEnd) {
        // console.log('parallax is ACTIVE');
        paraSpeed = (windowScroll * 0.2 - offsetTop * 0.2).toFixed(0);
        $(thisElem).css({
          'transform': 'translate3d(0, ' + paraSpeed + 'px, 0)'
        });
      } else if (windowScroll < parallaxStart) {
        // console.log('parallax has not begun');
      } else if (windowScroll > parallaxEnd) {
        // console.log('parallax is over');
      }
      ;
    });
    $('.special-parallax-vertical-01').each(function () {
      var thisElem = $(this),
        thisHeight = thisElem.height(),
        windowHeight = $(window).height(),
        offset = thisElem.offset(),
        offsetTop = offset.top,
        parallaxStart = offsetTop - windowHeight,
        parallaxEnd = offsetTop + thisHeight,
        paraSpeed;
      $(window).on('scroll', function () {
        var windowScroll = $(window).scrollTop();
        if (windowScroll > parallaxStart && windowScroll < parallaxEnd) {
          paraSpeed = (windowScroll * 0.2 - offsetTop * 0.2).toFixed(0);
          $(thisElem).css({
            'transform': 'translate3d(0, ' + -paraSpeed + 'px, 0)'
          });
        } else if (windowScroll < parallaxStart) {} else if (windowScroll > parallaxEnd) {}
        ;
      });
      var windowScroll = $(window).scrollTop();
      if (windowScroll > parallaxStart && windowScroll < parallaxEnd) {
        paraSpeed = (windowScroll * 0.2 - offsetTop * 0.2).toFixed(0);
        $(thisElem).css({
          'transform': 'translate3d(0, ' + -paraSpeed + 'px, 0)'
        });
      } else if (windowScroll < parallaxStart) {} else if (windowScroll > parallaxEnd) {}
      ;
    });
    $('.special-parallax-vertical-02').each(function () {
      var thisElem = $(this),
        thisHeight = thisElem.height(),
        windowHeight = $(window).height(),
        offset = thisElem.offset(),
        offsetTop = offset.top,
        parallaxStart = offsetTop - windowHeight,
        parallaxEnd = offsetTop + thisHeight,
        paraSpeed;
      $(window).on('scroll', function () {
        var windowScroll = $(window).scrollTop();
        if (windowScroll > parallaxStart && windowScroll < parallaxEnd) {
          paraSpeed = (windowScroll * 0.05 - offsetTop * 0.05).toFixed(0);
          $(thisElem).css({
            'transform': 'translate3d(0, ' + -paraSpeed + 'px, 0)'
          });
        } else if (windowScroll < parallaxStart) {
          // console.log('parallax has not begun');
        } else if (windowScroll > parallaxEnd) {
          // console.log('parallax is over');
        }
        ;
      });
      var windowScroll = $(window).scrollTop();
      if (windowScroll > parallaxStart && windowScroll < parallaxEnd) {
        paraSpeed = (windowScroll * 0.05 - offsetTop * 0.05).toFixed(0);
        $(thisElem).css({
          'transform': 'translate3d(0, ' + -paraSpeed + 'px, 0)'
        });
      } else if (windowScroll < parallaxStart) {} else if (windowScroll > parallaxEnd) {}
      ;
    });

    /* global slider */
    if ($('.component.slider-stack').length) {
      var slideOnMobile = false;
      var slideInterval = window.DGS.GlobalSliderConfig.slideInterval;
      var inEditMode = $("body").hasClass("mode-editing");
      var mobileMaxWidth = 700;
      var isMobileWidth;
      var setSliderHeights = function setSliderHeights() {
        $('.slider-stack-wrapper').each(function () {
          var maxHeight = Math.max.apply(null, $('.component.slider-stack', $(this)).map(function () {
            return $(this).height();
          }).get());
          $(this).css('height', maxHeight);
          $('.slider-spinner', $(this)).hide();
          $('.slider-tabs', $(this)).css('opacity', 1);
        });
      };
      var setupSliderNavigation = function setupSliderNavigation($sliderStackWrapper) {
        var showOnMobile = slideOnMobile ? "show-on-mobile" : "";
        var $slides = $sliderStackWrapper.find('.component.slider-stack'),
          $tabs,
          tabsHtml = "<div class='slider-tabs'><div class='tabs-content'>",
          arrowLeft = "<div class='slider-arrow-left " + showOnMobile + "'></div>",
          arrowRight = "<div class='slider-arrow-right " + showOnMobile + "'></div>",
          spinner = "<div class='slider-spinner'>Loading&#8230;</div>";

        //build up slider tabs
        if (!$sliderStackWrapper.find('.slider-tabs').length) {
          $slides.each(function () {
            tabsHtml += "<div class='slider-tab'></div>";
          });
          tabsHtml += "</div></div>";
          $sliderStackWrapper.append(tabsHtml);
          $tabs = $sliderStackWrapper.find('.slider-tabs');
        }

        //build up slider arrows
        if (!$sliderStackWrapper.find('.slider-arrow-left').length) $sliderStackWrapper.append(arrowLeft);
        if (!$sliderStackWrapper.find('.slider-arrow-right').length) $sliderStackWrapper.append(arrowRight);

        //spinner
        if (!$sliderStackWrapper.find('.slider-spinner').length) $sliderStackWrapper.append(spinner);
      };
      var cleanupSliderNavigation = function cleanupSliderNavigation($sliderStackWrapper) {
        $sliderStackWrapper.find('.slider-tabs').remove();
        $sliderStackWrapper.find('.slider-arrow-left').remove();
        $sliderStackWrapper.find('.slider-arrow-right').remove();
        $sliderStackWrapper.find('.slider-spinner').remove();
      };
      var buildSlider = function buildSlider() {
        $('.component.slider-stack').css('height', 'auto');
        $('.slider-stack-wrapper').each(function () {
          var currentIndex = 0,
            $slides = $(this).find('.component.slider-stack'),
            $tabs = $(this).find('.slider-tabs'),
            timeInterval = -1;
          var rotateSlides = function rotateSlides(goback) {
            if (goback) {
              //move backward
              if (currentIndex == 1) currentIndex = $slides.length;else currentIndex--;
            } else {
              //move forward
              if (currentIndex == $slides.length) currentIndex = 1;else currentIndex++;
            }
            $slides.filter('.active-slide').removeClass('active-slide');
            $slides.filter('.slide-backwards').removeClass('slide-backwards');
            $tabs.find('.slider-tab.active-tab').removeClass('active-tab');
            $slides.eq(currentIndex - 1).addClass('active-slide');
            if (goback) $slides.eq(currentIndex - 1).addClass('slide-backwards');
            $tabs.find('.slider-tab:nth-child(' + currentIndex + ')').addClass('active-tab');
          };
          $('.slider-arrow-left').click(function () {
            window.clearInterval(timeInterval);
            rotateSlides(true);
          });
          $('.slider-arrow-right').click(function () {
            window.clearInterval(timeInterval);
            rotateSlides();
          });
          $('.slider-tab').click(function () {
            window.clearInterval(timeInterval);
            var tabIndex = $tabs.find('.slider-tab').index($(this));
            if (tabIndex + 1 < currentIndex) {
              currentIndex = tabIndex + 2;
              rotateSlides(true);
            } else {
              currentIndex = tabIndex;
              rotateSlides();
            }
          });
          $(".component.slider-stack .component-content", $(this)).on("swipeleft", function (event) {
            window.clearInterval(timeInterval);
            rotateSlides();
          });
          $(".component.slider-stack .component-content", $(this)).on("swiperight", function (event) {
            window.clearInterval(timeInterval);
            rotateSlides(true);
          });
          setSliderHeights();
          currentIndex = 0;
          window.clearInterval(timeInterval);

          //mark first as active
          rotateSlides();

          //start interval rotation
          timeInterval = setInterval(rotateSlides, slideInterval);
          $(this).hover(function () {
            // pause rotation on mouse over
            clearInterval(timeInterval);
          }, function () {
            // restart rotation on mouse leave
            timeInterval = setInterval(rotateSlides, slideInterval);
          });
        });
      };
      var setupGroup = function setupGroup($group) {
        var isMobile = isMobileWidth || $group.filter('.content-overflow').length;
        if (isMobile && !slideOnMobile) {
          if ($group.parent().is(".slider-stack-wrapper")) {
            cleanupSliderNavigation($group.parent());
            $group.unwrap();
          }
        } else {
          if (!$group.parents('.slider-stack-wrapper').length && !inEditMode) {
            $group.wrapAll('<div class="slider-stack-wrapper " />');
          } else if (inEditMode) {
            $group.each(function (index) {
              $(this).addClass("slide-index");
              $(this).attr("data-index", "slide :" + (index + 1));
            });
          }
        }
        if (!isMobile || slideOnMobile) {
          setupSliderNavigation($group.parent());
        }
      };
      window.DGS.Resizer.getInstance().register(function () {
        var $group = null;
        var slideOnMobileClass = "slide-on-mobile";
        isMobileWidth = window.matchMedia('(max-width: ' + mobileMaxWidth + 'px)').matches;
        $('.component').each(function () {
          var $this = $(this);
          if ($this.hasClass('slider-stack')) {
            //Checking only if false; if one in the group is set to true, it will apply to the entire stack
            if (!slideOnMobile) {
              slideOnMobile = $this.hasClass(slideOnMobileClass);
            }
            if (!$group) $group = $this;else $group = $.merge($group, $this);
          } else {
            if ($group) {
              setupGroup($group);
            }
            $group = null;
          }
        });
        if ($group) {
          setupGroup($group);
        }
        if (slideOnMobile) {
          $('.slider-stack-wrapper').addClass(slideOnMobileClass);
        }

        //show spinner and reset slider height
        $('.slider-stack-wrapper .active-slide').removeClass('active-slide');
        $('.slider-stack-wrapper .slider-spinner').show();
        $('.slider-stack-wrapper .slider-tabs').css('opacity', 0);
      });
      window.DGS.Event.getInstance().on(window.DGS.Events.RESIZE_DONE, function () {
        buildSlider();
      });
    }
    /* global slider - end */

    // breadcrumbs global redirect fix
    $('html').attr('lang') === 'en' && $('a[href="/"]').length && $('a[href="/"]').attr('href', '/?redirect=0');
    // breadcrumbs global redirect fix - end

    window.DGS.Resizer.getInstance().register(function () {
      var windowHeight = $(window).height(),
        headerHeight = $('header').outerHeight(),
        windowWidth = $(window).width(),
        windowScroll = $(window).scrollTop(),
        coverFooterHeight = null;
      $('.is-window-height').each(function () {
        var offset = $(this).offset();
        $(this).css('height', windowHeight - offset.top);
      });
      $('.is-min-window-height').each(function () {
        var offset = $(this).offset();
        $(this).css('height', windowHeight - offset.top);
        //$(this).css('min-height',windowHeight);
      });
      $('.round-spot .image-spot-element-image').each(function () {
        var $this = $(this),
          $img = $('img', $this);
        $this.css('height', $img.css('width'));
      });
      if (!window.matchMedia('(max-width: 1020px)').matches) {
        $('body').css('margin-top', 0);
      } else {
        $('body').css('margin-top', 0);
      }
      ;
    });
  });
})(jQuery);