import { resolveImageSrc } from '@demant/wdh';
(function ($) {
  window.DGS.NewsListSpotConfig = {
    equalHeaderHeight: false,
    equalLocationHeight: false,
    equalDateHeight: false,
    equalTextHeight: false
  };
  window.DGS.OnLoad.getInstance().register(function () {
    var c = 0;
    $('.news-list-spot').each(function () {
      var $this = $(this);
      if (window.DGS.NewsListSpotConfig.equalHeaderHeight) window.DGS.EqualHeight.getInstance().register($('.news-header', $this), true);
      if (window.DGS.NewsListSpotConfig.equalLocationHeight) window.DGS.EqualHeight.getInstance().register($('.news-location', $this), true);
      if (window.DGS.NewsListSpotConfig.equalDateHeight) window.DGS.EqualHeight.getInstance().register($('.news-date', $this), true);
      if (window.DGS.NewsListSpotConfig.equalTextHeight) window.DGS.EqualHeight.getInstance().register($('.news-text', $this), false);
      window.DGS.EqualHeight.getInstance().register($('.news-link', $this), false);
      window.DGS.DrawSeparators.getInstance().register($('.component-content ul li', $this), 'news-list-spot-style' + c++);
    });
  });
})(jQuery);
(function ($) {
  window.DGS.NewsListSpotConfig.equalHeaderHeight = false;
  window.DGS.NewsListSpotConfig.equalLocationHeight = false;
  window.DGS.NewsListSpotConfig.equalDateHeight = false;
  window.DGS.NewsListSpotConfig.equalTextHeight = false;
  window.DGS.OnLoad.getInstance().register(function () {
    if ($('.news-list-spot').length) {
      $('.news-list-spot').each(function () {
        $(".component-content > ul > li", $(this)).each(function () {
          var $this = $(this);

          //for layout
          $this.wrapInner("<div class='news-item-wrapper'></div>");

          //if no <p> present wrapp text in <p>
          if (!$(".news-text p", $this).length) {
            $(".news-text", $this).wrapInner("<p></p>");
          }

          //move the image outside wrapper
          var $image = $(".news-image", $this);
          if ($image.length) {
            $image.detach();
            $this.prepend($image);
            var $img = $("img", $image);
            var src = $img.length ? resolveImageSrc($img[0]) : '';
            if (src) $image.css('backgroundImage', "url('" + src + "')");
          }

          //move the link outside wrapper
          var $link = $(".news-link", $this).detach();
          if ($link) $this.append($link);

          //ifinner wrapper for header
          if ($(".news-header", $this).length) {
            $(".news-header", $this).wrapInner("<div class='news-header-wrapper'></div>");
          }
          $this.click(function () {
            var href = $(".news-link .link", $this).attr("href");
            if (href.length) {
              window.location.href = $(".news-link .link", $this).attr("href");
            }
          });
        });
      });

      //set the posts height
      window.DGS.Resizer.getInstance().register(function () {
        $('.news-list-spot').each(function () {
          var $this = $(this),
            width = $this.find(".component-content > ul > li:first-child").css('width');
          if (window.matchMedia('(min-width: 768px)').matches) {
            $(".component-content > ul > li", $this).css('height', parseInt(width, 10) * 1.40);
            var newsRows = Math.ceil($('.component-content > ul > li', $(this)).length / 3);
            for (var i = 0; i < newsRows; i++) {
              var selector = ":nth-child(n+" + (i * 3 + 1) + "):nth-child(-n+" + (i + 1) * 3 + ")";
              //"n+1" "-n+3";
              //"n+4" "-n+6"
              //"n+7" "-n+9"
              //"n+10" "-n+12"
              window.DGS.EqualHeight.getInstance().trigger($('.component-content > ul > li' + selector + ' .news-item-wrapper:first-child .news-header', $(this)), true); //no image
              window.DGS.EqualHeight.getInstance().trigger($('.component-content > ul > li' + selector + '.news-image + .news-item-wrapper .news-header', $(this)), true); //with image
            }
            $(".component-content .news-image", $this).css('height', "40%");
          } else {
            $(".component-content > ul > li", $this).height('auto');
            $('.component-content > ul > li .news-item-wrapper .news-header', $(this)).height('auto');
            $(".component-content .news-image", $this).css('height', width);
          }
        });
      });
    }
  });
})(jQuery);