import WDHFeatureB2BCartSpot from '../../../wdh-feature/wdh-feature-b2b/src/js/cart-spot';

// eslint-disable-next-line wrap-iife
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('#header, #header .component').each(function () {
      $(this).addClass('component-ready animate');
    });
    if (!$('body.new-navigation').length) {
      $('#header .component .shopping-cart').each(function () {
        var shoppingCart = document.createElement('div');
        shoppingCart.className = 'component wdh-feature-b2b-cartspot';
        shoppingCart.innerHTML = '<div class="component-content"></div>';
        $(this).parent().append(shoppingCart);
        $(this).remove();
        new WDHFeatureB2BCartSpot().run();
      });
      $('.component.link-list.nav li a').each(function () {
        var href = $(this).attr('href');
        // if href in link and page url after slash are the same:
        if (window.location.pathname.indexOf(href) === 0) {
          $(this).addClass('active');
        }
      });
      var mySpans = '<span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>';
      $('.nav-expander').append(mySpans);
      $('.nav-expander > .component-content').remove();
      var $logoHeight = $('.nav-container .component.image .component-content a img').attr('height');
      $('.nav-container').css('height', $logoHeight);
      $('.nav-expander').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('nav-expanded');
        $('#header .nav').slideToggle(200);
      });
      if (languageSpot.length > 0) {
        $('.extranet-menu-anonymous').css('margin-right', '1em');
      }
      window.DGS.EqualHeight.getInstance().register($('#footer .row.alpha .component-content > ul > li'));
      window.DGS.Resizer.getInstance().register(function () {
        var $moveToTop = $('.move-to-top');
        var $header = $('#header');
        var windowHeight = $(window).height();
        var headerHeight = $('#header').outerHeight();
        if ($moveToTop.length) {
          if (window.matchMedia('(min-width: 1020px)').matches) {
            if (!$header.hasClass('moved')) {
              var $first = $($moveToTop[0]);
              $header.insertAfter($first);
              $header.addClass('moved');
              $('.is-window-height').each(function () {
                var $this = $(this);
                $this.removeClass('is-window-height');
                $this.addClass('header-moved-is-window-height');
              });
              $('.is-min-window-height').each(function () {
                var $this = $(this);
                $this.removeClass('is-min-window-height');
                $this.addClass('header-moved-is-min-window-height');
              });
              window.DGS.Resizer.getInstance().execute();
            }
          } else if ($header.hasClass('moved')) {
            $header.insertBefore($('#content'));
            $header.removeClass('moved');
            $('.header-moved-is-window-height').each(function () {
              var $this = $(this);
              $this.addClass('is-window-height');
              $this.removeClass('header-moved-is-window-height');
            });
            $('.header-moved-is-min-window-height').each(function () {
              var $this = $(this);
              $this.addClass('is-min-window-height');
              $this.removeClass('header-moved-is-min-window-height');
            });
            window.DGS.Resizer.getInstance().execute();
          }
          if ($header.hasClass('moved')) {
            $('.header-moved-is-window-height').each(function () {
              var $this = $(this);
              $this.css('height', windowHeight - headerHeight);
            });
            $('.header-moved-is-min-window-height').each(function () {
              var $this = $(this);
              $this.css('height', windowHeight - headerHeight);
              window.DGS.Event.getInstance().trigger('banner-spot.height.changed');
            });
          }
        }
      });
      window.DGS.Scroller.getInstance().register(function (scrollTop) {
        var $moveToTop = $('.move-to-top');
        var $header = $('#header.moved');
        var $prev;
        var offset;
        var bottom;
        var $next;
        if ($moveToTop.length && $header.length) {
          $prev = $header.prev();
          $next = $header.next();
          offset = $prev.offset();
          bottom = offset.top + $prev.outerHeight() - scrollTop;
          if (bottom < 0) {
            $header.addClass('fixed');
            $next.css('margin-top', "".concat($header.outerHeight(), "px"));
          } else {
            $header.removeClass('fixed');
            $next.css('margin-top', 'inherit');
          }
        }
      });
    }
    $('#wrapper .once-per-session.popup').each(function () {
      var $this = $(this);
      var cookie = DGS.Cookies.getInstance().readCookie('italian-medical-alert');
      if (!cookie) {
        $this.addClass('popup-show');
        $('.popup-close', $this).click(function () {
          window.DGS.Cookies.getInstance().createCookie('italian-medical-alert');
          $this.removeClass('popup-show');
        });
      }
    });
    window.openNav = openNav;
    window.closeNav = closeNav;

    /**
     * IE- Handling
     */
    /**
     * Detects IE Version.
     * Found on: https://codepen.io/gapcode/pen/vEJNZN
     * @return int (Version Number) / false if its is not internet explorer
     */
    var detectIE = function detectIE() {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }
      var trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }
      var edge = ua.indexOf('Edge/');
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      }

      // other browser
      return false;
    };
    if (detectIE()) {
      // Is IE

      $('.component.no-ie').each(function () {
        $(this).hide();
      });
      $('.component.ie-only').each(function () {
        $(this).show();
      });
    } else {
      // Is not IE

      $('.component.ie-only').each(function () {
        $(this).hide();
      });
    }
  });
})(jQuery);
function openNav() {
  document.getElementById('myNav').classList.add('open');
}
function closeNav() {
  document.getElementById('myNav').classList.remove('open');
}