/* eslint-disable */
(function ($) {
  window.DGS.CenterSpotConfig = {
    mapBrowserWidth: false
  };
  window.DGS.OnLoad.getInstance().register(function () {
    //extra div to style regions overview:
    $('.center-region-item').wrapAll('<div class="region-list">');
    $('.center-region-item').css('cursor', 'pointer');
    function scrollToDiv(element, navheight) {
      var offsetTop = element.offset().top;
      var totalScroll = offsetTop - navheight;
      $('body,html').animate({
        scrollTop: totalScroll
      }, 500);
    }
    $('.center-region-item').click(function () {
      var el = $(this).attr('data-anchorregion');
      var elWrapped = $("[id='" + el + "']");
      scrollToDiv(elWrapped, 60);
      return false;
    });
    $('.center-region-item').hover(function () {
      var reg = $(this).attr('data-mapcoords');
      var myhash = "#";
      var hashreg = myhash.concat(reg);
      $(hashreg).css('fill', '#cbc9c4'); // think about making the fill colors configurable via Sitecore, enabling customisation for other sites
    }, function () {
      var reg = $(this).attr('data-mapcoords');
      var myhash = "#";
      var hashreg = myhash.concat(reg);
      $(hashreg).css('fill', '#6b6867');
    });

    //link from map to centers - overrides global 'a' scrolling on oticon (main.js) when loaded after

    $('.map a').each(function () {
      $(this).on('click', function (e) {
        e.preventDefault();
        var hrefid = $(this).attr('href');
        var centerdiv = $('div' + hrefid);
        scrollToDiv(centerdiv, 60);
        return false;
      });
    });
  });
})(jQuery);