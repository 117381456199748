import WDHAccordionSpot from './wdh-accordion-spot';
var wdhAccordionSpot = new WDHAccordionSpot('.accordion-spot:not(.tabs)');
wdhAccordionSpot.run();
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    $('.accordion-spot-element__title').on('click', function () {
      var target = $(this);
      setTimeout(function () {
        var subnavigationHeight = $('.level2 > ul.current').length ? $('.level2 > ul.current').outerHeight(true) : 0;
        var headerHeight = $('#header').outerHeight(true);
        var correction = headerHeight + subnavigationHeight + 14;
        $('body,html').animate({
          scrollTop: $(target).offset().top - correction
        });
      }, 950);
    });
  });
})(jQuery, window.DGS);