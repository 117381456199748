function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// Import this file to enable default GTM tracking

var commonTracking = {
  event: 'GAevent',
  eventCategory: 'HCL'
};
var USE_LOCATION = function USE_LOCATION(accepted) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Search – use location',
    eventLabel: accepted ? 'Accepted' : 'Rejected'
  });
};
var SEARCH_PHRASE = function SEARCH_PHRASE(searchPhrase) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Search',
    eventLabel: searchPhrase
  });
};
var HIGHLIGHT_FROM_LIST = function HIGHLIGHT_FROM_LIST(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic view',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};
var HIGHLIGHT_FROM_MAP = function HIGHLIGHT_FROM_MAP(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Highlight Clinic - Map',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};
var CLINIC_CLICK = function CLINIC_CLICK(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic Info',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};
var GET_DIRECTIONS = function GET_DIRECTIONS(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic - Driving instructions',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};
var PHONE_NUMBER = function PHONE_NUMBER(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic - Phone',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};
var PHONE_NUMBER_FROM_LIST = function PHONE_NUMBER_FROM_LIST(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic list - Phone',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};
var WEBSITE_LINK = function WEBSITE_LINK(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic - Website',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};
var EMAIL_LINK = function EMAIL_LINK(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic - Email',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};
var OPEN_FILTER_LIST = _objectSpread(_objectSpread({}, commonTracking), {}, {
  eventAction: 'Clinic Filter - filter opened'
});
var ADD_FILTER = function ADD_FILTER(filterName) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic Filter - filter added',
    eventLabel: filterName
  });
};
var REMOVE_FILTER = function REMOVE_FILTER(filterName) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic Filter - filter removed',
    eventLabel: filterName
  });
};
var track = function track(trackingObj) {
  return window.DGS && window.DGS.GTMTracking && window.DGS.GTMTracking.getDataLayer().push(trackingObj);
};
window.DGS.Event.getInstance().on('trackLocationsUseLocation', function (accepted) {
  track(USE_LOCATION(accepted));
});
window.DGS.Event.getInstance().on('trackLocationsSearchPhrase', function (searchPhrase) {
  track(SEARCH_PHRASE(searchPhrase));
});
window.DGS.Event.getInstance().on('trackLocationsHighlightFromList', function (clinicName, clinicPostCode) {
  track(HIGHLIGHT_FROM_LIST(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsHighlightFromMap', function (clinicName, clinicPostCode) {
  return track(HIGHLIGHT_FROM_MAP(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsClinicClick', function (clinicName, clinicPostCode) {
  return track(CLINIC_CLICK(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsGetDirections', function (clinicName, clinicPostCode) {
  return track(GET_DIRECTIONS(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsPhoneNumber', function (clinicName, clinicPostCode) {
  return track(PHONE_NUMBER(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsPhoneNumberList', function (clinicName, clinicPostCode) {
  return track(PHONE_NUMBER_FROM_LIST(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsWebsiteLink', function (clinicName, clinicPostCode) {
  return track(WEBSITE_LINK(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsEmailLink', function (clinicName, clinicPostCode) {
  return track(EMAIL_LINK(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsOpenFilterList', function () {
  track(OPEN_FILTER_LIST);
});
window.DGS.Event.getInstance().on('trackLocationsAddFilter', function (filterName) {
  return track(ADD_FILTER(filterName));
});
window.DGS.Event.getInstance().on('trackLocationsRemoveFilter', function (filterName) {
  return track(REMOVE_FILTER(filterName));
});