import React, { useState, useEffect, useRef } from 'react';
import { ComponentBootstrap } from '@demant/react-bootstrap';
import flexSpotSchema from '@demant/react-bootstrap/lib/schemas/flex-spot';

const RoadmapBox = ({ header, desc }) => {
  const boxRef = useRef();
  const [ isVisible, setVisible ] = useState(false);

  useEffect(() => {
    const options = {
      threshold: 0.7
    };

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisible(true);
        observer.observe(boxRef.current);
      }
    }, options);

    observer.observe(boxRef.current);

    return () => observer.unobserve(boxRef.current);
  }, []);

  return (
    <div ref={boxRef} className={`roadmap__box ${isVisible ? 'is-visible' : ''}`}>
      <div className="roadmap__box__content">
        <div className="roadmap__box__header" dangerouslySetInnerHTML={{ __html: header }} />
        <div className="roadmap__box__desc" dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
    </div>
  );
};

const RoadmapLpReactComponent = ({ settings }) => {
  const background = settings.preElementItems.filter(el => el.type === 'image')[0];
  return (
    <div className="roadmap__container">
      <div className="roadmap__content">
        <img src={background.image.src} className="roadmap__background" />
        {settings.elements.map((el, index) => (
          <RoadmapBox
            key={index}
            header={el.taggedItems.header.html.html}
            desc={el.taggedItems.description.html.html}
          />
        ))}
      </div>
    </div>
  )
};

const c = new ComponentBootstrap('.flex-spot.roadmap-lp');
c.schemaData = flexSpotSchema;
c.reactApp = RoadmapLpReactComponent;
setTimeout(() => c.run(), 0);
