(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $(document).click(function (event) {
      if (!$(event.target).closest('.extranet-summary').length) {
        if ($('.extranet-summary').is(":visible")) {
          $('.success-message').hide();
        }
      }
    });
    $('.extranet-request-account').each(function () {
      var $this = $(this),
        $countryInput = $('input#main_0_CountryTextBox', $this);
      $countryInput.autocomplete({
        source: function source(request, response) {
          $.ajax({
            url: '/webservices/autocomplete.svc/search/_/' + $countryInput.val(),
            type: 'GET',
            dataType: 'json',
            success: function success(data) {
              response(data.data);
            },
            error: function error(xhr, textStatus, errorThrown) {
              console.log('error extranet-request-account ' + textStatus);
            }
          });
        },
        open: function open() {
          $("input#main_0_CountryTextBox", $this).autocomplete("widget").css("width", $countryInput.innerWidth());
        }
      }).autocomplete("widget").addClass("request-account-country");
    });
    if ($('body.new-navigation').length > 0) {
      var wrapperClasses = '.name, .title, .company, .comapny, .address, .city, .country, .email, .password, .repeat-password';
      var componentsClasses = '.extranet-forgot-password, .extranet-reset-password, .extranet-request-account, .extranet-complete-account, .extranet-update-account';
      $('input, textarea').each(function () {
        if ($(this).val()) {
          $(this).closest(wrapperClasses).find('label').addClass('completed');
        }
      });
      $(componentsClasses).on('focus', 'input, textarea', function () {
        $(this).closest(wrapperClasses).find('label').addClass('completed');
        $(this).closest(wrapperClasses).addClass('active');
      });
      $(componentsClasses).on('blur', 'input, textarea', function () {
        if ($(this).val()) {
          $(this).closest(wrapperClasses).find('label').addClass('completed');
        } else {
          $(this).closest(wrapperClasses).find('label').removeClass('completed');
        }
        $(this).closest(wrapperClasses).removeClass('active');
      });
    }
  });
})(jQuery);