(function ($) {
  "use strict";

  window.DGS.OnContentLoaded.getInstance().register(function () {
    $.fn.scrollPosReaload = function () {
      if (localStorage) {
        var posReader = localStorage.posStorage;
        if (posReader) {
          $(window).scrollTop(posReader);
          localStorage.removeItem("posStorage");
        }
        $(this).click(function () {
          localStorage.posStorage = $(window).scrollTop(0);
        });
        return true;
      }
      return false;
    };
    $(document).ready(function () {
      $('.reset-button').scrollPosReaload();
    });
  });
})(jQuery);