(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var $allSubmenus = $('#wrapper .component.link-list.submenu, ' + '#wrapper .component.link-list.second-submenu, ' + '#wrapper .component.link-list.third-submenu');

    /// Add missing h3 to first-level submenu
    $('#wrapper .component.link-list.submenu', function () {
      var $this = $(this);
      var $h3 = $('h3', $this);
      if (typeof $h3 === 'undefined') {
        $(this).append('<h3>&nbsp;</h3>');
      }
    });

    /// Highlight current active location in submenus
    $allSubmenus.each(function () {
      var $submenu = $(this);
      var longestMatchLength = 0;
      var $longestMatchLi;
      $('li', $submenu).each(function () {
        var $li = $(this);
        var $a = $('a', $li);
        var href = $a.attr('href');
        if (window.location.pathname.indexOf(href) == 0) {
          if (href.length > longestMatchLength) {
            longestMatchLength = href.length;
            $longestMatchLi = $li;
          }
        }
      }).promise().done(function () {
        // Highlight only the longest matching link in each submenu
        if (typeof $longestMatchLi !== 'undefined') {
          $longestMatchLi.addClass('active');
        }
      });
    });

    // Set text of submenu title to be the current main area
    $('#wrapper .component.link-list.submenu h3').each(function () {
      var $h3 = $(this);
      var titleFound = false;
      $('#wrapper #header .component.link-list.nav li a, ' + '#wrapper #header .component.rich-text.top.nav a').each(function () {
        var href = $(this).attr('href');
        if (window.location.pathname.indexOf(href) == 0) {
          $h3.text($(this).text());
          titleFound = true;
        }
      });
      if ($h3.closest('.component.link-list.submenu').hasClass('keep-title')) {
        titleFound = true;
      }
      if (!titleFound) {
        $h3.text(document.title);
      }
    });

    // Set text of second-submenu title to be the current active page name
    $('#wrapper .component.link-list.second-submenu h3').each(function () {
      var $h3 = $(this);
      var $active = $('ul li.active a', $(this).parent());
      $h3.text($active.text());
    });

    /// Click handler for li elements in submenus
    $allSubmenus.each(function () {
      var $this = $(this);
      var $ul = $('ul', $this);
      var $li = $('li', $ul);
      var $a = $('a', $li);
      $a.on('click', function (e) {
        e.stopPropagation();
      });
      $li.on('click', function (e) {
        var $a = $('a', $(this));
        if (submenuHelpers.isThin($this)) {
          if ($a.attr('href').substr(0, 1) === '#') {
            submenuHelpers.slideUp($this);
          }
        }
        window.location.href = $a.attr('href');
      });
    });

    /// Shared helper methods for the more complicated scroll/resizing features
    var submenuHelpers = function () {
      var expandClass = 'submenu-expanded';
      var slideTimeMs = 400;
      var helper;

      // Give this method a target element and a source, it will return a
      // function to synchronize the heights (optionally with a third element as offset).
      var synchronizeHeightsFactory = function synchronizeHeightsFactory($targetElement, $sourceElement, $measureOffsetElement) {
        var additionalHeight = $measureOffsetElement.outerHeight() || 0;
        return function () {
          $targetElement.css('height', parseInt($sourceElement.css('height'), 10) + additionalHeight);
        };
      };

      // Helper to synchronize heights if the element is sticky
      var synchronizedStickySpacing = function synchronizedStickySpacing($element, $ul, $h3) {
        if (isSticky($element)) {
          return synchronizeHeightsFactory($element, $ul, $h3);
        }
      };
      var isExpanded = function isExpanded($element) {
        return $element.hasClass(expandClass);
      };
      var isSticky = function isSticky($element) {
        return $element.hasClass('sticky');
      };
      var isThin = function isThin($element) {
        return $element.hasClass('thin');
      };
      helper = {
        isExpanded: isExpanded,
        isSticky: isSticky,
        isThin: isThin,
        removeSticky: function removeSticky($element) {
          $element.css('height', '');
          $('.component-content', $element).css('top', '');
          $element.removeClass('sticky');
        },
        addSticky: function addSticky($element, stickyPositionOffset) {
          $element.addClass('sticky');
          // console.log(stickyPositionOffset);
          $('.component-content', $element).css('top', stickyPositionOffset);
        },
        // Get the amount of space that should be added to the page offset of elements located below this one
        getPageSpacingHeight: function getPageSpacingHeight($element) {
          var measuredHeight = 0;
          if (!isThin($element)) {
            measuredHeight = $element.find('.component-content').outerHeight();
          } else if (isExpanded($element)) {
            // When expanded, the element itself will have a css 'height' equal to it's expanded height
            measuredHeight = $element.outerHeight();
            // We could use parseInt and $element.css('height'), but it is slightly inaccurate
          } else {
            measuredHeight = $element.find('.component-content').outerHeight();
          }
          return measuredHeight;
        },
        // Get the amount of space that should be added to the sticky position of elements below this one
        getStickyPositionHeight: function getStickyPositionHeight($element) {
          var measuredHeight = 0;
          if (!isThin($element)) {
            measuredHeight = $element.find('.component-content').outerHeight();
          } else {
            // The position should be below the h3, hidden when expanded
            measuredHeight = $element.find('.component-content > h3').outerHeight();
          }
          return measuredHeight;
        },
        // Set the elements css "height" to its actual size by measurement
        updateElementHeight: function updateElementHeight($element) {
          var measuredHeight = 0;
          if (!isThin($element)) {
            $element.css('height', '');
          } else {
            if (isExpanded($element)) {
              // This causes the element to take all the needed space when expanded
              measuredHeight = $element.find('.component-content').outerHeight();
            } else {
              // This causes the element to take only space for showing the h3
              measuredHeight = $element.find('.component-content > h3').outerHeight();
            }
            $element.css('height', measuredHeight);
          }
        },
        slideUp: function slideUp($element) {
          var $ul = $('ul', $element);
          var $h3 = $('.component-content > h3', $element);
          var completeFunction = function completeFunction() {
            // Remove "display: none" as that is our default state and should not be
            // in the element style.
            $ul.css('display', '');
            $element.removeClass(expandClass);
          };
          var slideUpOptions = {
            duration: slideTimeMs,
            progress: synchronizedStickySpacing($element, $ul, $h3),
            complete: completeFunction
          };
          $ul.slideUp(slideUpOptions);
        },
        slideDown: function slideDown($element) {
          var $ul = $('ul', $element);
          var $h3 = $('.component-content > h3', $element);
          var completeFunction = function completeFunction() {
            $element.addClass(expandClass);
            $element.removeClass('submenu-is-expanding');
          };
          var slideDownOptions = {
            duration: slideTimeMs,
            progress: synchronizedStickySpacing($element, $ul, $h3),
            complete: completeFunction
          };
          $element.addClass('submenu-is-expanding');
          $ul.slideDown(slideDownOptions);
        }
      };
      return helper;
    }();

    ///
    /// Sticky submenus when scrolling past
    ///
    (function (submenuHelpers) {
      var stickyWhenScrolledPast = function stickyWhenScrolledPast(windowScrollTop) {
        // Note here that submenu does not need to be thin (mobile) in order to stick to top
        $('#wrapper .component.link-list.submenu, ' + '#wrapper .component.link-list.second-submenu.thin').each(function () {
          var $this = $(this);
          var offset = $this.offset();
          var offsetTop = typeof offset !== 'undefined' ? offset.top : 0;
          var scrollTop = $this.data('scroll-top');
          var pageSpacingOffset = $this.data('page-spacing-offset');
          var stickyPositionOffset = $this.data('sticky-position-offset');
          if (offsetTop <= 0 && typeof scrollTop === 'undefined') {
            return;
          }
          if (typeof scrollTop === 'undefined') {
            $this.data('scroll-top', offsetTop);
            scrollTop = offsetTop;
          }

          // debug info:
          /* console.log(
              "windowScrollTop", windowScrollTop,
              "page-spacing-offset", pageSpacingOffset,
              "scroll-top", scrollTop,
              "name", $this.attr('class').split(' ').filter(function(x) {
                  return x.indexOf("submenu") != -1;
              })[0]
          ); // */

          if (scrollTop < windowScrollTop + pageSpacingOffset) {
            if (!submenuHelpers.isSticky($this)) {
              // Avoid doing this too often (performance) by only doing it when not already sticky
              submenuHelpers.updateElementHeight($this);
            }
            submenuHelpers.addSticky($this, stickyPositionOffset);
          } else {
            submenuHelpers.removeSticky($this);
          }
        });
      };
      var queuedCalculateWithDelayOperation;

      /// Function to recalculate scroll values with a delay
      var calculateStoredScrollValuesWithDelay = function calculateStoredScrollValuesWithDelay() {
        var delayForCalculation = 100; // small delay to allow for DOM modifications before we calculate heights.

        if (typeof queuedCalculateWithDelayOperation !== 'undefined') {
          // Cancel previous operation of the same kind if execution has not started
          clearTimeout(queuedCalculateWithDelayOperation);
        }
        queuedCalculateWithDelayOperation = setTimeout(function () {
          queuedCalculateWithDelayOperation = undefined;

          // Calculate values for first submenu
          $('#wrapper .component.link-list.submenu').each(function () {
            submenuHelpers.updateElementHeight($(this));
            $(this).data('scroll-top', $(this).offset().top);
            var pageSpacingOffset = $('#wrapper #header.thin').outerHeight() || $('.header-desktop-top').outerHeight() || $('.header-mobile-top').outerHeight();
            $(this).data('page-spacing-offset', pageSpacingOffset || 0);
            $(this).data('sticky-position-offset', pageSpacingOffset || 0);

            // debug info:
            // console.log('"submenu": page-spacing-offset set to', pageSpacingOffset || 0);
          });

          // Calculate values for second-submenu
          $('#wrapper .component.link-list.second-submenu').each(function () {
            submenuHelpers.updateElementHeight($(this));
            $(this).data('scroll-top', $(this).offset().top);
            var $submenu = $('#wrapper .component.link-list.submenu');
            var pageSpacingOffset = $('#wrapper #header.thin').outerHeight() + submenuHelpers.getPageSpacingHeight($submenu);
            $(this).data('page-spacing-offset', pageSpacingOffset || 0);
            var stickyPositionOffset = $('#wrapper #header.thin').outerHeight() + submenuHelpers.getStickyPositionHeight($submenu);
            $(this).data('sticky-position-offset', stickyPositionOffset || 0);

            // debug info:
            // console.log('"second-submenu": page-spacing-offset set to', pageSpacingOffset || 0);
          });

          // Use current values to update the display now
          stickyWhenScrolledPast(window.pageYOffset);
        }, delayForCalculation);
      };
      window.DGS.Scroller.getInstance().register(function (windowScrollTop) {
        // Running update when scrolling
        stickyWhenScrolledPast(windowScrollTop);
      });
      window.DGS.Resizer.getInstance().register(function () {
        // Update with correct values when browser resizes or mobile rotates
        calculateStoredScrollValuesWithDelay();
      });
    })(submenuHelpers);

    ///
    /// Slide-down submenus on click
    ///
    (function (submenuHelpers) {
      var $submenus = $('#wrapper .component.link-list.submenu, ' + '#wrapper .component.link-list.second-submenu');
      $submenus.each(function () {
        var $submenu = $(this);
        var $h3 = $('h3', $submenu);
        $h3.on('click', function () {
          if (submenuHelpers.isExpanded($submenu)) {
            submenuHelpers.slideUp($submenu);
          } else {
            submenuHelpers.slideDown($submenu);

            // Hide all other slide-downs if the menu to open is sticky
            $submenus.each(function () {
              if ($submenu[0] === $(this)[0]) return;
              if (submenuHelpers.isExpanded($(this))) {
                submenuHelpers.slideUp($(this));
              }
            });
          }
        });
      });
    })(submenuHelpers);

    ///
    /// Content overflow responsive js
    ///
    (function (submenuHelpers, $allSubmenus) {
      var submenuContentOverflowCheck = function submenuContentOverflowCheck() {
        $allSubmenus.each(function () {
          var $submenu = $(this);
          var $submenuLi = $('li', $submenu);
          var submenuLiFirstCr;
          var submenuLiLastCr;
          if ($submenu.hasClass('thin-mq')) {
            // Media query override, while this is active
            // we should not measure anything.
            return;
          }

          // Remove mobile style from measurement element
          $submenu.removeClass('thin');
          if ($submenuLi.length) {
            submenuLiFirstCr = $submenuLi[0].getBoundingClientRect();
            submenuLiLastCr = $submenuLi[$submenuLi.length - 1].getBoundingClientRect();
            if (submenuLiFirstCr.top < submenuLiLastCr.top) {
              $submenu.addClass('thin');
            } else {
              submenuHelpers.removeSticky($submenu);
            }
          }
        });
      };

      /// Media-query breaks to content regardless of overflow
      var submenuContentMediaQueryCheck = function submenuContentMediaQueryCheck() {
        var query = window.matchMedia('(max-width: 400px)').matches;
        $allSubmenus.each(function () {
          if (query) {
            $(this).addClass('thin');
            $(this).addClass('thin-mq');
          } else {
            $(this).removeClass('thin');
            $(this).removeClass('thin-mq');
          }
        });
      };
      $(function () {
        submenuContentMediaQueryCheck();
        submenuContentOverflowCheck();
      });
      window.DGS.Resizer.getInstance().register(function () {
        submenuContentMediaQueryCheck();
        submenuContentOverflowCheck();
      });
    })(submenuHelpers, $allSubmenus);
  });
  window.DGS.OnContentLoaded.getInstance().register(function () {
    var pageMenuSticky = function pageMenuSticky() {
      var menu = document.querySelector('.link-list.page-menu-sticky');
      if (!document.body.contains(menu)) {
        return;
      }
      var menuLinks = menu.querySelectorAll('a[href^="#"]');
      var headerHeight = document.getElementById('header').offsetHeight;
      var breakPointsForSections = [];
      menuLinks.forEach(function (link) {
        var section = document.querySelector(link.hash).nextElementSibling;
        var sectionBreakPoint = section.offsetTop - headerHeight;
        breakPointsForSections.push(sectionBreakPoint);
      });
      var removeCurrentFromMenuLink = function removeCurrentFromMenuLink() {
        menuLinks.forEach(function (link) {
          link.parentElement.classList.remove('current');
        });
      };
      var markCurrentMenuLink = function markCurrentMenuLink() {
        breakPointsForSections.forEach(function (breakPoint, index) {
          var sectionAtTheTopAndThenWithinViewPort = window.pageYOffset >= breakPoint && window.pageYOffset < breakPointsForSections[index + 1];
          var lastSectionAtTheTop = window.pageYOffset >= breakPoint && index === breakPointsForSections.length - 1;
          if (sectionAtTheTopAndThenWithinViewPort || lastSectionAtTheTop) {
            removeCurrentFromMenuLink();
            menuLinks[index].parentElement.classList.add('current');
          }
        });
      };
      markCurrentMenuLink();
      window.addEventListener('scroll', markCurrentMenuLink);
    };
    window.DGS.Resizer.getInstance().register(pageMenuSticky);
  });
})(jQuery);