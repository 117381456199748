//For GA4
var EVENT = "genericGAEvent";
var DGS = window.DGS;
DGS.GTMTracking = DGS.GTMTracking || {
  getDataLayer: function getDataLayer() {
    if (typeof dataLayer !== "undefined") {
      return dataLayer;
    }
    return {
      push: function push(data) {
        console.log("GTM not implemented. Debug data: ", data);
      }
    };
  }
};
export var isDataLayer = function isDataLayer() {
  return window.DGS && window.DGS.GTMTracking && window.DGS.GTMTracking.getDataLayer();
};
export var pushEventToDataLayer = function pushEventToDataLayer(eventData) {
  if (isDataLayer) {
    window.DGS.GTMTracking.getDataLayer().push(eventData);
  } else {
    console.log("DGS GTM not implemented. Debug data: ".concat(eventData));
  }
};
export var buildAndPushGenericGAEvent = function buildAndPushGenericGAEvent(category, action, label, value) {
  var eventData = {
    event: EVENT,
    event_category: category,
    event_action: action,
    event_label: label,
    event_value: value
  };
  pushEventToDataLayer(eventData);
};
export var trackHCLSearch = function trackHCLSearch(searchTerm) {
  return buildAndPushGenericGAEvent("hcl", "search", searchTerm, "");
};
export var trackHCLFormSubmit = function trackHCLFormSubmit(clinicName) {
  return buildAndPushGenericGAEvent("hcl", "form_submitted", clinicName, "");
};
export var trackHCLClinicClick = function trackHCLClinicClick(clinicName) {
  return buildAndPushGenericGAEvent("hcl", "click_clinic", clinicName, "");
};
export var trackHearingTest = function trackHearingTest(stepNumber, stepName, scoreValue) {
  return buildAndPushGenericGAEvent("hearing_test", stepNumber, stepName, scoreValue);
};
export var trackLoginClick = function trackLoginClick(loginType) {
  return buildAndPushGenericGAEvent("login", loginType, "", "");
};
export var trackProductClick = function trackProductClick(productName, source) {
  return buildAndPushGenericGAEvent("product_click", productName, source, "none");
};
export var trackWebsiteInternalSearch = function trackWebsiteInternalSearch(hasResult) {
  var result = hasResult ? "result" : "no_result";
  var searchTerm = window.DGS.getQueryParameter("q");
  buildAndPushGenericGAEvent("internal_search", result, searchTerm);
};
export var trackFormSubmit = function trackFormSubmit(formName) {
  var clinicName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  return buildAndPushGenericGAEvent("form_submitted", formName, clinicName, "");
};
export var addTrackingToProductClick = function addTrackingToProductClick(e, href, target, clickSource) {
  e.preventDefault();
  var setTarget = target ? target : "_self";
  if (href.includes("/hearing-aid-users/hearing-aids/")) {
    var productName = href.split("/").at(-1);
    var product = ['alpha', 'viron', 'zerena', 'leox'];
    product.forEach(function (p) {
      if (p === productName) {
        trackProductClick(p, clickSource);
      }
    });
  }
  window.open(href, setTarget);
};
export var trackDownloadInResourceCenter = function trackDownloadInResourceCenter(fileType, fileName, selectedFiltersArray) {
  var getFilters = function getFilters(index) {
    var _selectedFiltersArray;
    return ((_selectedFiltersArray = selectedFiltersArray[index]) === null || _selectedFiltersArray === void 0 ? void 0 : _selectedFiltersArray.length) > 0 ? selectedFiltersArray[index].join(", ") : "none";
  };
  var eventData = {
    event: EVENT,
    event_category: "download",
    event_action: fileType,
    event_label: fileName,
    event_selected_product: getFilters(0),
    event_technologies_styles: getFilters(1),
    event_document_category: getFilters(2),
    event_marketing_program: getFilters(3)
  };
  pushEventToDataLayer(eventData);
};
export var addTrackingToFormSubmit = function addTrackingToFormSubmit() {
  var clinicName = new URLSearchParams(window.location.search).get("place");
  var formName = window.location.pathname;
  if (!clinicName) {
    trackFormSubmit(formName);
  } else {
    trackFormSubmit(formName, clinicName);
  }
};
export var trackMobileStoreLinks = function trackMobileStoreLinks(e) {
  e.preventDefault();
  if (e.currentTarget.href.includes('apple')) {
    buildAndPushGenericGAEvent(window.location.pathname.split('/').lastItem, 'appstore_click', 'bernafon_app', '');
  }
  if (e.currentTarget.href.includes('google')) {
    buildAndPushGenericGAEvent(window.location.pathname.split('/').lastItem, 'googleplay_click', 'bernafon_app', '');
  }
  window.open(e.currentTarget.href);
};