(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    $('.component.blog').each(function () {
      // Push the page below the sticky top navigation
      if (!$('body.new-navigation').length) {
        $('#wrapper #content').css('padding-top', $('#wrapper #header').outerHeight());
      }

      // Here comes some magic, moving elements around (bernafon requirements?)
      // Place title after date
      $('.blog-title', $(this)).insertAfter($('.blog-date', $(this)));

      // Move the image BEFORE the blog-categories component
      var $blogImage = $('.blog-image', $(this)).insertBefore($('.blog-categories'));
      $blogImage.wrap("<div class='component component-ready'><div class='component-content'></div></div>");
      $blogImage.css('font-size', 0); // This is to remove the "magic" space after the closing divs
    });
    $('.component.blog-author').each(function () {
      $(".blog-author-about", $(this)).insertAfter($(".blog-author-image", $(this)));
    });

    // Remove the related blogs if there are none
    $('.component.related-blogs').each(function () {
      var $lines = $("ul li", $(this));
      if ($lines.length === 0) {
        $(this).remove();
      }
    });
  });
})(jQuery);
$('.component.blog iframe').each(function (index, item) {
  var container = $('<div class="iframe-container"></div>');
  container.insertBefore(item);
  container.append(item);
});
(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    var $author_details = $(".component.author-details"),
      $author_related_blogs = $(".component.author-related-blogs"),
      author_headline_text = "All posts from"; // Made this a var in case we want some translation or change it

    if ($author_details.length > 0 && $author_related_blogs.length > 0 && $author_details.find(".author-intro").children('h2').length > 0) {
      var author_name = $author_details.find(".author-intro").children('h2').html();
      $author_related_blogs.children(".component-content").prepend("<div class='author-header'>" + author_headline_text + " <span class='author-name'>" + author_name + "</span></div>");
    }
    $('.author-related-blogs > .component-content > ul').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 1251,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          dots: true,
          adaptiveHeight: true,
          arrows: false
        }
      }]
    });
  });
})(jQuery);