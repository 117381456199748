(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    $('.box-spot').each(function () {
      $('.stretch:not(:nth-child(3n+6))', $(this)).remove();
      window.DGS.Justify.getInstance().apply($('.box-spot-items-container', $(this)), 'div');
    });
    DGS.Resizer.getInstance().register(function () {
      $('.box-spot').each(function () {
        $('.box-spot-item').each(function () {
          $(this).height($(this).width() * 0.73);
        });
      });
    });
  });
})(jQuery, window.DGS);