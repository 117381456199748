(function ($, DGS) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    //for old platform and MyBusiness which generates links differently
    var $languageSpot = $("body:not(.new-navigation.user-not-logged-in) #header .component.language-selector-spot");
    $languageSpot.each(function () {
      var $languageSpotSingle = $(this);
      var $ul = $(".component-content ul", $languageSpotSingle),
        liCount = $("li", $ul).length,
        $slider = $(".slider", $ul),
        $languagePopup = $("#header .component.language-selector-spot .popup--choose-language"),
        urlLength = 0,
        sliderWidth,
        tempThis,
        tempURL = "",
        theURL;
      if (liCount > 0) {
        if ($slider.length === 0) {
          $slider = $('<div class="slider"></div>');
          $ul.prepend($slider);
        }
        sliderWidth = 100 / liCount;
        $slider.css({
          width: sliderWidth + "%"
        });
        theURL = document.location.href.replace("http://", "").replace("https://", "");
        $("li", $ul).each(function (index) {
          tempURL = $("a", $(this)).attr("href").replace("http://", "").replace("https://", "");
          if (theURL.indexOf(tempURL) == 0 && tempURL.length > urlLength) {
            urlLength = tempURL.length;
            if (tempThis !== undefined) tempThis.removeClass("active");
            tempThis = $(this);
            $(this).addClass("active");
            $slider.addClass("active");
          }
          $(this).click(function (e) {
            e.preventDefault();
            var goTo = $(this).find("a").attr("href");
            $("li", $ul).removeClass("active");
            $(this).addClass("active");
            $slider.addClass("active");
            setTimeout(function () {
              window.location = goTo;
            }, 500);
          });
        });
      }
    });
    //for new platform, and user not logged to myBusiness
    var languageSelectorSpot = document.querySelector("body.new-navigation.user-not-logged-in #header .component.language-selector-spot");
    var languages = languageSelectorSpot.querySelectorAll("ul li a");
    var urlHost = document.location.host;
    var urlPath = document.location.pathname;
    var urlQueryString = document.location.search;
    var adjustURL = function adjustURL(link) {
      link.href = link.protocol + "//" + link.host + urlPath + urlQueryString;
    };
    var markCurrentLanguage = function markCurrentLanguage(link) {
      if (urlHost === link.host) {
        link.classList.add("active");
      }
    };
    var updateLanguage = function updateLanguage(link) {
      adjustURL(link);
      markCurrentLanguage(link);
    };
    if (languages.length) {
      languages.forEach(updateLanguage);
    }
  });
})(jQuery, window.DGS);